import React, {useState , useEffect}  from 'react'
import api from '../../../services/api'
import tokenServer from '../../../services/tokenServer'

import { Link, useParams, useHistory } from 'react-router-dom';
import HeaderProfile from '../../../components/HeaderProfile';
import Preloader from '../../../components/Preloader'
import Pagination from '../../../components/Pagination'
import Img from '../../../assets/produto-sem-imagem-1.gif'

import { Row, TextInput, Select, CollapsibleItem, Collapsible, Icon} from 'react-materialize';

import './style.css'

export default function MeusAnuncios() {
    const params = useParams()
    const [load, setLoad] = useState(true);
    const [meusAnuncios, setMeusAnuncios] = useState([])
    const [name, setName] = useState('')
    const [handleInputName, setHandleInputName] = useState('')
    const [tipo, setTipo] = useState('')
    const [totalPages, setTotalPages] = useState(0);
    const token = localStorage.getItem('token')
    const history = useHistory()
    let time = null


    async function loadAds(page, name, type) {
        try {
            const access_token = await tokenServer.getToken()
            const response = await api.get(`ads/?page=${page}&product_name=${name}&product_type=${type}`,{ headers: {
                    authorizationuser: token,
                    Authorization: `Bearer ${access_token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })
            setTotalPages(response.data.total/20)
            setMeusAnuncios(response.data.ads);
            
        } catch (error) {
        }
        setLoad(false)
        window.scrollTo(0, 0)
    }
    useEffect(() => {
        loadAds(params.page, name, tipo);
    }, [params.page])

    useEffect(() => {
        loadAds( params.page , name, tipo);
        history.push('/meus-anuncios/page/1')
    }, [name, tipo])

    function handleName(value) {
        setHandleInputName(value)
        clearTimeout(time)
        time = setTimeout(() => {
            setName(value)
        }, 1000)   
    }
    

    function filterAds(event){
        event.preventDefault();
    }

    function Figure(props){
        if(props.url){
            return <img src={props.url} alt="figure" className="img-card"/>
        }else{
            return <img src={Img} alt="figure" className="img-card"/>
        }
    }


    return(
        <div id="meus-anuncios">
            <Preloader load={load}/>
            <HeaderProfile title="Meus Anúncios"/>
            <div className="container">
            <div className="row">
                <Collapsible accordion={false} popout={true}>
                    <CollapsibleItem
                        expanded={false}
                        header="Filtros para busca"
                        icon={<Icon>search</Icon>}
                        node="div"
                    >
                        <form onSubmit={filterAds} className="form-container">
                            <Row>
                                <div className="col s12 l6">
                                    <TextInput
                                        noLayout={true}
                                        className="form-item"
                                        id="TextInput-4"
                                        label="Buscar"
                                        value={handleInputName}
                                        onChange={event => handleName(event.target.value)}
                                    />
                                </div>
                                <div className="col l6 s12">
                                <Select
                                        noLayout={true}
                                        id="Select-9"
                                        multiple={false}
                                        
                                        options={{
                                            classes: '',
                                            dropdownOptions: {
                                            alignment: 'left',
                                            autoTrigger: true,
                                            closeOnClick: true,
                                            constrainWidth: true,
                                            coverTrigger: true,
                                            hover: false,
                                            inDuration: 150,
                                            onCloseEnd: null,
                                            onCloseStart: null,
                                            onOpenEnd: null,
                                            onOpenStart: null,
                                            outDuration: 250
                                            }
                                        }}
                                            value={tipo}
                                            onChange={event => setTipo(event.target.value)}
                                        >
                                        <option
                                            value=""
                                        >
                                            Todas as Categorias
                                        </option>
                                        <option value="Frutas">
                                            Frutas
                                        </option>
                                        <option value="Hortaliças">
                                            Hortaliças
                                        </option>
                                        <option value="Mudas">
                                            Mudas
                                        </option>
                                        <option value="Plantas Ornamentais">
                                            Plantas Ornamentais
                                        </option>
                                        </Select>

                                </div>
                            </Row>
                        </form>
                    </CollapsibleItem>
                </Collapsible>
            </div>
            <ul className="container-list">
                    {meusAnuncios.map(anuncio => (
                        <li key={anuncio.id} className="col s12 l3 m6">
                            <Link to={`/meus-anuncios/${anuncio.id}`}>
                                <div className="card">
                                    <Figure url={anuncio.image_url} />
                                    <div className="container-description">
                                        <p><strong>Tipo de Anúncio:</strong> {anuncio.ad_type}</p>
                                        <p className="truncated"><strong>Produto:</strong> {anuncio.product_name}</p>
                                        <p className="truncated"><strong>Quantidade:</strong> {anuncio.amount+ ' ' + anuncio.amount_type} </p>
                                        <p className="truncated"><strong>Frequencia:</strong> {anuncio.frequency}</p>
                                        <p className="truncated"><strong>Cidade:</strong> {anuncio.address.city}</p>
                                    </div>
                                </div>
                            </Link>
                        </li>

                    ))}
                </ul>
                <div class="pagination">
                    <Pagination numberPages={totalPages} currentPage={params.page}/>
                </div>
            </div>
        </div>
        
    )
}