import React, { useState, useEffect } from 'react'
import api from '../../../services/api'
import tokenServer from '../../../services/tokenServer'

import { useHistory, useParams } from 'react-router-dom';

import HeaderProfile from '../../../components/HeaderProfile';
import Preloader from '../../../components/Preloader'
import ModalError from '../../../components/ModalError'

import { Button, TextInput, Select } from 'react-materialize';



import './style.css'

export default function MeusAnunciosEdit() {
    const params = useParams()
    const [load, setLoad] = useState(true);
    const [modal, setModal] = useState(false);
    const [erro, setErro] = useState('');
    const [address, setAddress] = useState([])
    const [image, setImage] = useState(null)
    const [address_id, setAddress_id] = useState('')
    const [product_name, setProduct_name] = useState('')
    const [ad_status, setAd_status] = useState('Ativo')
    const [ad_type, setAd_type] = useState('')
    const [amount, setAmount] = useState('')
    const [amount_type, setAmount_type] = useState('')
    const [frequency, setFrequency] = useState('')
    const [description, setDescription] = useState('')
    const [product_type, setProduct_type] = useState('Frutas')
    const [url, setUrl] = useState('')
    const token = localStorage.getItem('token')
    const user_type = localStorage.getItem('user_type')

    async function loadData(){
        try {
            const access_token = await tokenServer.getToken()
            const responseAddress = await api.get('address/',{ headers: {
                    authorizationuser: token,
                    Authorization: `Bearer ${access_token}`,
                }
            })
            setAddress(responseAddress.data)
            const response = await api.get(`ads/${params.id}`,{ headers: {
                authorizationuser: token,
                Authorization: `Bearer ${access_token}`,
            }
            })
            setDescription(response.data.description)
            setProduct_name(response.data.product_name)
            setAd_status(response.data.ad_status)
            setProduct_type(response.data.product_type)
            setAmount_type(response.data.amount_type)   
            setAmount(response.data.amount)
            setFrequency(response.data.frequency)
            setAddress_id(response.data.address.id)
            if(user_type.includes("Comprador")){
                setAd_type("Demanda")
            } 
            if(user_type.includes("Agricultor Familiar")){
                setAd_type("Oferta")
            } 
        } catch (error) {
        }
        setLoad(false)
    }

    useEffect(() => {
        loadData()
    }, [token, params.id, user_type])

    function handleFile(e){
        setImage(e.target.files[0])
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setModal(false)
        setLoad(true)
        const access_token = await tokenServer.getToken()
        const data = new FormData()
        
        try{
            if(image){
                data.append('image', image)
            }
            data.append('product_name', product_name)
            data.append('amount', amount)
            data.append('amount_type', amount_type)
            data.append('frequency', frequency)
            data.append('description', description)
            data.append('product_type', product_type)
            data.append('ad_type', ad_type)
            data.append('address_id', address_id)
            data.append('ad_status', ad_status)
            await api.put(`ads/${params.id}`, data ,{ headers: {
                authorizationuser: token,
                Authorization: `Bearer ${access_token}`,
                'content-type': 'multipart/form-data',
            }
            })
            setLoad(false)
            setErro("Anúncio Atualizado")
            setUrl(`/meus-anuncios/${params.id}`)
            // history.push(`/meus-anuncios/${params.id}`)
        } catch (erro) {
            setErro("Erro, por favor verifique se os campos estão preenchidos corretamente")
        }
        setLoad(false)
        setModal(true)
    }

    

    return(
        <div id="edit-anuncio">
            <Preloader load={load}/>
            <ModalError show={modal} error={erro} url={url} />
            <HeaderProfile title="Editar Anúncio"/>
            <div className="wrapper">
                <div className="container">
                        <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col l6 s12">
                                <div class = "file-field input-field">
                                    <div class = "btn green lighten-1">
                                        <span>Buscar</span>
                                        <input type = "file" onChange={handleFile}/>
                                    </div>
                                    
                                    <div class = "file-path-wrapper">
                                        <input class = "file-path validate" type = "text"
                                            placeholder = "Imagem do Anuncio" />
                                    </div>
                                </div>
                            </div>
                            <div className="col l6 s12 ">
                                <TextInput
                                    noLayout
                                    id="TextInput-4"
                                    label="Nome do Produto"
                                    value={product_name}
                                    onChange={event => setProduct_name(event.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col l4 s6">
                                <TextInput
                                    noLayout
                                    id="TextInput-4"
                                    label="Quantidade"
                                    value={amount}
                                    onChange={event => setAmount(event.target.value)}
                                />
                            </div>
                            <div className="col l2 s6 ">
                            <Select
                                    noLayout
                                    id="Select-9"
                                    multiple={false}
                                    options={{
                                        classes: '',
                                        dropdownOptions: {
                                        alignment: 'left',
                                        autoTrigger: true,
                                        closeOnClick: true,
                                        constrainWidth: true,
                                        coverTrigger: true,
                                        hover: false,
                                        inDuration: 150,
                                        onCloseEnd: null,
                                        onCloseStart: null,
                                        onOpenEnd: null,
                                        onOpenStart: null,
                                        outDuration: 250
                                        }
                                    }}
                                        value={amount_type}
                                        onChange={event => setAmount_type(event.target.value)}
                                    >
                                    <option value="Unidade">
                                        Unidade
                                    </option>
                                    <option value="KG">
                                        Kg
                                    </option>
                                    <option value="Toneladas">
                                        Toneladas
                                    </option>
                                </Select>
                            </div>
                            <div className="col l6 s12 ">
                                <TextInput
                                    noLayout
                                    id="TextInput-4"
                                    label="Frequencia"
                                    value={frequency}
                                    onChange={event => setFrequency(event.target.value)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s12">
                                <textarea 
                                    id="textarea2" 
                                    className="materialize-textarea"
                                    value={description}
                                    onChange={event => setDescription(event.target.value)}
                                >

                                </textarea>
                                <label for="textarea2">Descrição</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col l6 s12 ">
                                    <Select
                                        noLayout
                                        id="Select-9"
                                        label="Endereco"
                                        multiple={false}
                                        options={{
                                            classes: '',
                                            dropdownOptions: {
                                            alignment: 'left',
                                            autoTrigger: true,
                                            closeOnClick: true,
                                            constrainWidth: true,
                                            coverTrigger: true,
                                            hover: false,
                                            inDuration: 150,
                                            onCloseEnd: null,
                                            onCloseStart: null,
                                            onOpenEnd: null,
                                            onOpenStart: null,
                                            outDuration: 250
                                            }
                                        }}
                                            value={address_id}
                                            onChange={event => setAddress_id(event.target.value)}
                                        >
                                        {
                                            address.map(address => (
                                                <option key={address.id} value={address.id}>
                                                    {`${address.postal_code}, ${address.address}`}
                                                </option>
                                                
                                            ))
                                        }
                                       
                                        
                                    </Select>
                            </div>
                            <div className="col l3 s12 ">
                                <Select
                                    noLayout
                                    id="Select-9"
                                    label="Tipo do Produto"
                                    multiple={false}
                                    options={{
                                        classes: '',
                                        dropdownOptions: {
                                        alignment: 'left',
                                        autoTrigger: true,
                                        closeOnClick: true,
                                        constrainWidth: true,
                                        coverTrigger: true,
                                        hover: false,
                                        inDuration: 150,
                                        onCloseEnd: null,
                                        onCloseStart: null,
                                        onOpenEnd: null,
                                        onOpenStart: null,
                                        outDuration: 250
                                        }
                                    }}
                                        value={product_type}
                                        onChange={event => setProduct_type(event.target.value)}
                                    >
                                    <option value="Frutas">
                                        Frutas
                                    </option>
                                    <option value="Hortaliças">
                                        Hortaliças
                                    </option>
                                    <option value="Mudas">
                                        Mudas
                                    </option>
                                    <option value="Plantas Ornamentais">
                                        Plantas Ornamentais
                                    </option>
                                </Select>
                                </div>
                                <div className="col l3 s12 ">
                                <Select
                                    noLayout
                                    id="Select-9"
                                    label="Tipo do Produto"
                                    multiple={false}
                                    options={{
                                        classes: '',
                                        dropdownOptions: {
                                        alignment: 'left',
                                        autoTrigger: true,
                                        closeOnClick: true,
                                        constrainWidth: true,
                                        coverTrigger: true,
                                        hover: false,
                                        inDuration: 150,
                                        onCloseEnd: null,
                                        onCloseStart: null,
                                        onOpenEnd: null,
                                        onOpenStart: null,
                                        outDuration: 250
                                        }
                                    }}
                                        value={ad_status}
                                        onChange={event => setAd_status(event.target.value)}
                                    >
                                    <option value="Ativo">
                                        Ativo
                                    </option>
                                    <option value="Reservado">
                                        Reservado
                                    </option>
                                    <option value="Vendido">
                                        Vendido
                                    </option>
                                </Select>
                                </div>
                            </div>
                            <div className="row">
                            <Button
                                    className="green lighten-1"
                                    node="button"
                                    type="submit"
                                    waves="light"
                                    style={{
                                        float: 'right'
                                    }}
                                >
                                Atualizar
                            </Button>
                            </div>  
                        </form>
                </div>
            </div>     
        </div>
    )
}