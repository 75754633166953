import React, {useEffect, useState}  from 'react'
import api from '../../../services/api'
import tokenServer from '../../../services/tokenServer'

import { Link, useParams, useHistory } from 'react-router-dom';
import HeaderProfile from '../../../components/HeaderProfile';
import Preloader from '../../../components/Preloader'
import Pagination from '../../../components/Pagination'
import HeaderAnuncio from '../../../components/HeaderAnuncios'
import Img from '../../../assets/produto-sem-imagem-1.gif'

import { TextInput, Row, Select, Collapsible, CollapsibleItem, Icon } from 'react-materialize';


import './style.css'

export default function AnunciosList() {
    const params = useParams()
    const [load, setLoad] = useState(true);
    const [anuncios, setAnuncios] = useState([])
    const [name, setName] = useState('')
    const [handleInputName, setHandleInputName] = useState('')
    const [handleInputCity, setHandleInputCity] = useState('')
    const [city, setCity] = useState('')
    const [ad_type, setAd_type] = useState('');
    const [tipo, setTipo] = useState('')
    const [totalPages, setTotalPages] = useState(0);
    const token = localStorage.getItem('token')
    const user_type = localStorage.getItem('user_type')
    
    const history = useHistory()
    let time = null;

    async function loadAds() {
        try {
            const access_token = await tokenServer.getToken()
           
            const response = await api.get(`ads/all/?page=${params.page}&product_name=${name}&product_type=${tipo}&city=${city}&ad_type=${ad_type}`,{ headers: {
                Authorization: `Bearer ${access_token}`,
            }})
            setTotalPages(response.data.total/20)
            setAnuncios(response.data.ads);
            setLoad(false)
            window.scrollTo(0, 0)
        } catch (error) {
        }       
    }
    useEffect(() => {
        if(user_type === "Comprador"){
            setAd_type('Oferta')
        }else if(user_type === "Agricultor Familiar"){
            setAd_type('Demanda')
        }
    }, [])

    useEffect(() => {
        loadAds();
    }, [params.page])

    useEffect(() => {
        loadAds();
        history.push('/anuncios/page/1')
    }, [name, tipo, city, ad_type])

    function handleSubmit(event) {
        event.preventDefault();
    }

    function handleName(value) {
        setHandleInputName(value)
        clearTimeout(time)
        time = setTimeout(() => {
            setName(value)
        }, 1000)   
    }

    function handleCity(value) {
        setHandleInputCity(value)
        clearTimeout(time)
        time = setTimeout(() => {
            setCity(value)
        }, 1000)   
    }

    function HeaderAnuncios() {
        if(token){
            return <HeaderProfile title="Anúncios"/>
        }else {
            return <HeaderAnuncio title="Anúncios" />
        }
    }

    function Figure(props){
        if(props.url){
            return <img src={props.url} alt="figure" className="img-card"/>
        }else{
            return <img src={Img} alt="figure" className="img-card"/>
        }
    }
    

    return(
    <div id="anuncios">
        <Preloader load={load}/>
        <HeaderAnuncios />
        <div className="container">
                <div className="row">
                    <Collapsible accordion={false} popout={true}>
                        <CollapsibleItem
                            expanded={false}
                            header="Filtros para busca"
                            icon={<Icon>search</Icon>}
                            node="div"
                        >
                            <form className="form-container" onSubmit={handleSubmit}>
                            <Row>
                                <div className="col s12 l3">
                                    <TextInput
                                        noLayout={true}
                                        className="form-item"
                                        id="TextInput-4"
                                        label="Buscar"
                                        value={handleInputName}
                                        onChange={event => handleName(event.target.value)}
                                    />
                                </div>
                                <div className="col s12 l3">
                                    <TextInput
                                        noLayout={true}
                                        className="form-item"
                                        id="TextInput-4"
                                        label="Cidade"
                                        value={handleInputCity}
                                        onChange={event => handleCity(event.target.value)}
                                    />
                                </div>
                                <div className="col l3 s12">
                                <Select
                                        noLayout={true}
                                        id="Select-9"
                                        multiple={false}
                                        
                                        options={{
                                            classes: '',
                                            dropdownOptions: {
                                            alignment: 'left',
                                            autoTrigger: true,
                                            closeOnClick: true,
                                            constrainWidth: true,
                                            coverTrigger: true,
                                            hover: false,
                                            inDuration: 150,
                                            onCloseEnd: null,
                                            onCloseStart: null,
                                            onOpenEnd: null,
                                            onOpenStart: null,
                                            outDuration: 250
                                            }
                                        }}
                                            value={tipo}
                                            onChange={event => setTipo(event.target.value)}
                                        >
                                        <option
                                            value=""
                                        >
                                            Todas as Categorias
                                        </option>
                                        <option value="Frutas">
                                            Frutas
                                        </option>
                                        <option value="Hortaliças">
                                            Hortaliças
                                        </option>
                                        <option value="Mudas">
                                            Mudas
                                        </option>
                                        <option value="Plantas Ornamentais">
                                            Plantas Ornamentais
                                        </option>
                                        </Select>

                                </div>
                                <div className="col l3 s12">
                                <Select
                                        noLayout={true}
                                        id="Select-9"
                                        multiple={false}
                                        
                                        options={{
                                            classes: '',
                                            dropdownOptions: {
                                            alignment: 'left',
                                            autoTrigger: true,
                                            closeOnClick: true,
                                            constrainWidth: true,
                                            coverTrigger: true,
                                            hover: false,
                                            inDuration: 150,
                                            onCloseEnd: null,
                                            onCloseStart: null,
                                            onOpenEnd: null,
                                            onOpenStart: null,
                                            outDuration: 250
                                            }
                                        }}
                                            value={ad_type}
                                            onChange={event => setAd_type(event.target.value)}
                                        >
                                        <option
                                            value=""
                                        >
                                            Todos os Anúncios
                                        </option>
                                        <option value="Oferta">
                                            Ofertas
                                        </option>
                                        <option value="Demanda">
                                            Demandas
                                        </option>
                                    </Select>
                                </div>
                            </Row>
                        </form>
                        </CollapsibleItem>
                    </Collapsible>
                </div>
                <ul className="container-list">
                    {anuncios.map(anuncio => (
                        <li key={anuncio.id} className="col s12 l3 m6">
                            <Link to={`/anuncios/${anuncio.id}`}>
                                <div className="card">
                                    <Figure url={anuncio.image_url} />
                                    <div className="container-description">
                                        <p><strong>Tipo de Anúncio:</strong> {anuncio.ad_type}</p>
                                        <p className="truncated"><strong>Produto:</strong> {anuncio.product_name}</p>
                                        <p className="truncated"><strong>Quantidade:</strong> {anuncio.amount+ ' ' + anuncio.amount_type} </p>
                                        <p className="truncated"><strong>Frequencia:</strong> {anuncio.frequency}</p>
                                        <p className="truncated"><strong>Cidade:</strong> {anuncio.address.city}</p>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    ))}
                    
                </ul>
                <div class="pagination">
                    <Pagination numberPages={totalPages} currentPage={params.page} pagina="anuncios"/>
                </div>
        </div>
    </div>
    )
}