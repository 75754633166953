import React  from 'react'
import { Link } from 'react-router-dom';

import './style.css'

export default function Modalerror(props) {
    const pageNumbers = []
    for(let i = 1; i <= Math.ceil(props.numberPages); i++){
        pageNumbers.push(i)
    }

    if(props.pagina === "anuncios"){
        return(
            <ul id="pagination">
                {
                    pageNumbers.map(pageNumber =>{
                        if(props.currentPage === pageNumber.toString()){
                           return <li class="active"><Link to={`/anuncios/page/${pageNumber}`}>{pageNumber}</Link></li>
                        }else{
                           return <li id="page" class="waves-effect"><Link to={`/anuncios/page/${pageNumber}`}>{pageNumber}</Link></li>
                        }
                        
                    })
                }
            </ul>
        )
    }else{
        return(
            <ul id="pagination">
                {
                    pageNumbers.map(pageNumber =>{
                        if(props.currentPage === pageNumber.toString()){
                           return <li class="active"><Link to={`/meus-anuncios/page/${pageNumber}`}>{pageNumber}</Link></li>
                        }else{
                           return <li id="page" class="waves-effect"><Link to={`/meus-anuncios/page/${pageNumber}`}>{pageNumber}</Link></li>
                        }
                        
                    })
                }
            </ul>
        )
    }

    
}