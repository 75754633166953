import React, { useState, useEffect }  from 'react'
import api from './../../../services/api'
import tokenServer from '../../../services/tokenServer'
import { useParams } from 'react-router-dom';

import Preloader from './../../../components/Preloader'
import ModalError from './../../../components/ModalError'
import { Container, TextInput, Row, Col, Button, Select } from 'react-materialize';
import HeaderProfile from './../../../components/HeaderProfile';

import './style.css'

export default function EditEndereco() {
    const params = useParams()
    const [load, setLoad] = useState(true);
    const [modal, setModal] = useState(false);
    const [erro, setErro] = useState('');
    const [url, setUrl] = useState('');
    const [phone_number, setPhone_number] = useState('')
    const [phone_type, setPhone_type] = useState('')
    const token = localStorage.getItem('token')

    async function loadData(){
        try {
            const access_token = await tokenServer.getToken()
            const response = await api.get(`phones/${params.id}`,{ headers: {
                    authorizationuser: token,
                    Authorization: `Bearer ${access_token}`,
                }
            })
            setPhone_type(response.data.phone_type)
            setPhone_number(response.data.phone_number)
        } catch (error) {
        }
        setLoad(false)
    }

    useEffect(() => {
        loadData()
    }, [token , params.id])

    function mtel(v){
        v=v.replace(/\D/g,"");             //Remove tudo o que não é dígito
        v=v.replace(/^(\d{2})(\d)/g,"($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
        v=v.replace(/(\d)(\d{4})$/,"$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
        return v;
    }

    function handlePhoneNumber(value) {
        setPhone_number(mtel(value))
    }

    async function handleEditContato(event) {
        event.preventDefault();
        setLoad(true)
        setModal(false)
        const data = {phone_number, phone_type }
        try {
            const access_token = await tokenServer.getToken()
            await api.put(`phones/${params.id}`, data ,{ headers: {
                authorizationuser: token,
                Authorization: `Bearer ${access_token}`,
            }
            })
            setUrl('/perfil/contato')
            setErro("Contato Atualizado")
            setLoad(false)
            setModal(true)
        } catch (erro) {
            setErro("Erro, por favor verifique se os campos estão preenchidos corretamente")
        }
        setLoad(false)
        setModal(true)
    }

    return(
        <div id="edit-contato">
            <Preloader load={load}/>
            <ModalError show={modal} error={erro} url={url} />
            <HeaderProfile title="Editar Contato"/>
            <div className="wrapper">
            <Container className="z-depth-5">
            <form onSubmit={handleEditContato}>
                <h5>Editar contato</h5>
                <Row>
                        <Col m={6} s={12}>
                            <TextInput
                                    icon="phone"
                                    noLayout={true}
                                    id="TextInput-4"
                                    label="Telefone"
                                    value={phone_number}
                                    onChange={event => handlePhoneNumber(event.target.value)}
                                />
                            </Col>
                            <Col m={6} s={12}>
                                <Select
                                        noLayout
                                        id="Select-9"
                                        multiple={false}
                                        options={{
                                            classes: '',
                                            dropdownOptions: {
                                            alignment: 'left',
                                            autoTrigger: true,
                                            closeOnClick: true,
                                            constrainWidth: true,
                                            coverTrigger: true,
                                            hover: false,
                                            inDuration: 150,
                                            onCloseEnd: null,
                                            onCloseStart: null,
                                            onOpenEnd: null,
                                            onOpenStart: null,
                                            outDuration: 250
                                            }
                                        }}
                                            value={phone_type}
                                            onChange={event => setPhone_type(event.target.value)}
                                        >
                                        <option value="Fixo">
                                            Fixo
                                        </option>
                                        <option value="Celular">
                                            Celular
                                        </option>
                                        <option value="Comercial">
                                            Comercial
                                        </option>
                                </Select>
                            </Col>
                        </Row>
                        <Row>
                            <Button
                                node="button"
                                type="submit"
                                waves="light"
                                style={{
                                    float: 'right'
                                }}
                            >
                                Atualizar
                            </Button>
                        </Row>
                
            </form>
            
            </Container>
            </div>
        </div>
        
    )
}