import React, { useState }  from 'react'
import api from '../../../services/api'
import tokenServer from '../../../services/tokenServer'

import Preloader from './../../../components/Preloader'
import ModalError from './../../../components/ModalError'
import { Container, TextInput, Row, Col, Button, Select } from 'react-materialize';
import HeaderProfile from '../../../components/HeaderProfile';

import './style.css'

export default function NewEndereco() {
    const [load, setLoad] = useState(false);
    const [modal, setModal] = useState(false);
    const [erro, setErro] = useState('');
    const [url, setUrl] = useState('');
    const [phone_number, setPhone_number] = useState('')
    const [phone_type, setPhone_type] = useState('Residencial')
    const token = localStorage.getItem('token')

    function mtel(v){
        v=v.replace(/\D/g,"");             //Remove tudo o que não é dígito
        v=v.replace(/^(\d{2})(\d)/g,"($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
        v=v.replace(/(\d)(\d{4})$/,"$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
        return v;
    }

    function handlePhoneNumber(value) {
        setPhone_number(mtel(value))
    }


    async function handleNewContato(event) {
        event.preventDefault();
        setLoad(true)
        setModal(false)
        setPhone_number(phone_number)
        const data = {phone_number, phone_type }
        try {
            const access_token = await tokenServer.getToken()
            await api.post('phones/', data, { headers: {
                    authorizationuser: token,
                    Authorization: `Bearer ${access_token}`,
                }
            })
            setUrl('/perfil/contato')
            setErro("Contato Criado")
        } catch (erro) {
            setErro("Erro, por favor verifique se os campos estão preenchidos corretamente")
        }
        setLoad(false)
        setModal(true)
    }

    return(
        <div id="new-contato">
            <Preloader load={load}/>
            <ModalError show={modal} error={erro} url={url}/>
            <HeaderProfile title="Novo Contato"/>
            <div className="wrapper">
            <Container className="z-depth-5">
            <form onSubmit={handleNewContato}>
                <h5>Novo Contato</h5>
                <Row>
                   
                    <Col m={6} s={12}>
                        <TextInput
                            required
                            noLayout={true}
                            icon="phone"
                            maxlength="15"
                            id="TextInput-4"
                            label="(DDD) + Numero"
                            value={phone_number}
                            onChange={event => handlePhoneNumber(event.target.value)}
                        />
                    </Col>
                    <Col m={6} s={12}>
                        <Select
                                    noLayout
                                    id="Select-9"
                                    multiple={false}
                                    options={{
                                        classes: '',
                                        dropdownOptions: {
                                        alignment: 'left',
                                        autoTrigger: true,
                                        closeOnClick: true,
                                        constrainWidth: true,
                                        coverTrigger: true,
                                        hover: false,
                                        inDuration: 150,
                                        onCloseEnd: null,
                                        onCloseStart: null,
                                        onOpenEnd: null,
                                        onOpenStart: null,
                                        outDuration: 250
                                        }
                                    }}
                                        value={phone_type}
                                        onChange={event => setPhone_type(event.target.value)}
                                    >
                                    <option value="Residencial">
                                        Residencial
                                    </option>
                                    <option value="Celular">
                                        Celular
                                    </option>
                                    <option value="Comercial">
                                        Comercial
                                    </option>
                        </Select>
                    </Col>
                </Row>
                <Row>
                    <Button
                        node="button"
                        type="submit"
                        waves="light"
                        style={{
                            float: 'right'
                        }}
                    >
                        Cadastrar
                    </Button>
                </Row>
            </form>
            
            </Container>
            </div>
        </div>
        
    )
}