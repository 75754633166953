import React, { useState } from 'react'
import api from '../../services/api'
import tokenServer from '../../services/tokenServer'
import { Link, useHistory } from 'react-router-dom';

import Preloader from './../../components/Preloader'
import ModalError from './../../components/ModalError'

import './style.css'
import Header from '../../components/Header';


export default function Login() {
    const [load, setLoad] = useState(false);
    const [modal, setModal] = useState(false);
    const [urlModal, setUrlModal] = useState(false);
    const [erro, setErro] = useState('');
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    
    const history = useHistory()

    async function handleLogin(event) {
        event.preventDefault()
        setModal(false)
        setLoad(true)
        try {
            const access_token = await tokenServer.getToken()
            const response = await api.post('session/', { email, password },{ headers: {
                Authorization: `Bearer ${access_token}`,
            }})
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('user_id', response.data.user.id)
            localStorage.setItem('user_type', response.data.user.user_type)
            localStorage.setItem('user_name', response.data.user.name)
            setLoad(false)
            history.replace('/anuncios/page/1')
        } catch (error) {
            setErro('Erro, seu email ou sua senha estao incorretos')
            setUrlModal("/login")
            setLoad(false)
            setModal(true)
        }
        
    }


    return (
        <div id="login">
            <Preloader load={load}/>
            <ModalError show={modal} error={erro} url={urlModal} title={"Erro"} />
            <Header></Header>
            <div class="container">
                <div class="row">
                    <div class="col s12 m6 offset-m3">
                        <div class="card-panel z-depth-5">
                            <h4 class="center">Entrar</h4>
                            <div class="row">
                                <form class="col s12 m12" onSubmit={handleLogin}>
                                    <div class="row">
                                        <div class="input-field col s12 m12">
                                            <i class="material-icons prefix">account_circle</i>
                                            <input id="email" type="email" class="validate"
                                                required
                                                value={email}
                                                onChange={event => setEmail(event.target.value)}
                                            />
                                            <label for="email">Email</label>
                                        </div>
                                        <div class="input-field col s12">
                                            <i class="material-icons prefix">lock</i>
                                            <input id="password" type="password" class="validate"
                                                required
                                                minlength="6"
                                                maxlength="16"
                                                value={password}
                                                onChange={event => setPassword(event.target.value)}
                                            />
                                            <label for="password">Senha</label>
                                        </div>
                                    </div>
                                    <div class="row col s12 center">
                                        <button class="btn waves-effect waves-light button" type="submit" name="action">Entrar
                                  <i class="material-icons right">send</i>
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <span><Link to="/recuperar-senha">Esqueceu a senha?</Link></span>
                            <br />
                            <br />
                            <span>Não tem conta? Cadastre-se <Link to="/cadastro/termos-uso">aqui</Link>.</span>
                        </div>



                    </div>

                </div>

            </div>
        </div>
    )
}