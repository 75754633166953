import React, {useState}  from 'react'

import api from './../../../../services/api'
import tokenServer from '../../../../services/tokenServer'


import Preloader from './../../../../components/Preloader'
import ModalError from './../../../../components/ModalError'

import { Link } from 'react-router-dom';
import { Container, TextInput, Row, Col, Button } from 'react-materialize';
import './style.css'
import Header from '../../../../components/Header';


export default function CadProdutorUsusario() {
    const [load, setLoad] = useState(false);
    const [modal, setModal] = useState(false);
    const [urlModal, setUrlModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [erro, setErro] = useState('');
    const [name, setName] = useState('');
    const [cpf, setCpf] = useState('');
    const [property_name, setProperty_name] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    function CpfMask(v){
        v = v.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        v = v.replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        v = v.replace(/(\d{3})(\d)/, '$1.$2')
        v = v.replace(/(\d{3})(\d{1,2})/, '$1-$2')
        v = v.replace(/(-\d{2})\d+?$/, '$1') 
        return v;
    }

    function handleCpf(event){
        setCpf(CpfMask(event.target.value))
    }
    
    async function handleNewProdutorUsuario(event) {
        event.preventDefault();
        setModal(false)
        setLoad(true)
        const user_type = 'Agricultor Familiar';
        const data = {name, cpf, email, password, user_type, property_name}
        if(password === password2){
            try {
                const access_token = await tokenServer.getToken()
                await api.post('users', data,{ headers: {
                    Authorization: `Bearer ${access_token}`,
                }} )
                setModalTitle('Usuario Cadastrado')
                setErro("Usuario cadastrado com sucesso, agora você pode acessar sua dashboard logando no sistema")
                setUrlModal('/')
            } catch (erro) {
                if(erro.response.data.error === "CPF already exists!"){
                    setErro("Erro, CPF já cadastrado")
                }else if(erro.response.data.error === "Email already exists!"){
                    setErro("Erro, Email já cadastrado")
                }else {
                    setErro("Erro, por favor verifique se os campos estão preenchidos corretamente")
                }
                setModalTitle("Erro")
            }
        }else {
            setErro("Erro, senhas nao compativeis")
            setModalTitle("Erro")
        }
        setLoad(false)
        setModal(true)
    }

    return(
        
        <div id="cadastro-usuario-produtor">
           <Preloader load={load}/>
            <ModalError show={modal} error={erro} url={urlModal} title={modalTitle} />
            <Header></Header>
            <Container className="z-depth-5">
                <form onSubmit={handleNewProdutorUsuario}>
                    <h5>Dados do Produtor Rural</h5>
                    <Row>
                        <Col m={6} s={12}>
                            <TextInput 
                                required
                                noLayout={true}
                                id="TextInput-4"
                                label="Nome"
                                value={name}
                                onChange={event => setName(event.target.value)}
                            />
                        </Col>
                        <Col m={6} s={12}>
                            <TextInput 
                                required
                                // required pattern="[0-9]+$"
                                // required min="11" max="11"
                                noLayout={true}
                                id="TextInput-4"
                                label="CPF"
                                value={cpf}
                                onChange={handleCpf}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col m={12} s={12}>
                            <TextInput 
                                required
                                noLayout={true}
                                id="TextInput-4"
                                label="Nome da Propriedade"
                                value={property_name}
                                onChange={event => setProperty_name(event.target.value)}
                            />
                        </Col>
                    </Row>
                    <h5>Perfil</h5>
                    <Row>
                        <Col m={12} s={12}>
                            <TextInput 
                                required
                                noLayout={true}
                                id="TextInput-4"
                                label="E-mail"
                                validate
                                email
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col m={6} s={12}>
                            <TextInput 
                                required
                                noLayout={true}
                                id="TextInput-4"
                                label="Senha"
                                minlength="6"
                                maxlength="16"
                                password
                                value={password}
                                onChange={event => setPassword(event.target.value)}

                            />
                        </Col>
                        <Col m={6} s={12}>
                            <TextInput 
                                required
                                noLayout={true}
                                id="TextInput-4"
                                label="Repetir a Senha"
                                minlength="6"
                                maxlength="16"
                                password
                                value={password2}
                                onChange={event => setPassword2(event.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Link to="/cadastro" class="btn btn-voltar">Voltar</Link>
                        <Button
                                node="button"
                                type="submit"
                                waves="light"
                                style={{
                                    float: 'right'
                                }}
                            >
                            Cadastrar
                        </Button>
                    </Row>
                </form>
            </Container>
            
        </div>
        
    )
}