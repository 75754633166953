import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Home from './pages/Home'
import Anuncios from './pages/Anuncios/List'
import TermosUso from './pages/Cadastro/TermosDeUso'
import CadCompradorUsuario from './pages/Cadastro/Comprador/Usuario'
import CadProdutorUsuario from './pages/Cadastro/Produtor/Usuario'
import Cadastro from './pages/Cadastro/Escolha'
import MeusAnuncios from "./pages/MeusAnuncios/List";
import MeuAnuncioDetail from "./pages/MeusAnuncios/Detail";
import NovoAnuncio from "./pages/MeusAnuncios/New";
import AnuncioDetail from "./pages/Anuncios/Detail";
import EditAnuncio from "./pages/MeusAnuncios/Edit";
import ListEndereco from "./pages/Endereco/List";
import EditEndereco from "./pages/Endereco/Edit";
import NewEndereco from "./pages/Endereco/New";
import ListContato from "./pages/Contato/List";
import EditContato from "./pages/Contato/Edit";
import NewContato from "./pages/Contato/New";
import Perfil from "./pages/Perfil/Detail"
import EditPerfil from "./pages/Perfil/Edit"
import EditSenha from "./pages/Perfil/SenhaEdit"
import RecuperarSenha from "./pages/RecuperarSenha/EnvioEmail"
import NovaSenha from "./pages/RecuperarSenha/NovaSenha"
import Login from "./pages/Login";
import LoginAdmin from "./pages/Admin/Login";
import AnunciosList from "./pages/Admin/Anuncios/List";
import AnuncioEdit from "./pages/Admin/Anuncios/Edit";
import UsuariosList from "./pages/Admin/Usuarios/List";
import UsuarioEdit from "./pages/Admin/Usuarios/Edit";

import { isAuthenticated } from './auth'


const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        isAuthenticated() ? (
            <Component {...props} />
        ) : (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
    )} />
)


export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/login" exact component={Login} />
                <Route path="/recuperar-senha" exact component={RecuperarSenha} />
                <Route path="/nova-senha/:token" exact component={NovaSenha} />
                <Route path="/anuncios/:id" exact component={AnuncioDetail}/>
                <Route path="/cadastro/termos-uso" exact component={TermosUso} />
                <Route path="/cadastro/comprador/usuario" exact component={CadCompradorUsuario} />
                <Route path="/cadastro/produtor/usuario" exact component={CadProdutorUsuario} />
                <Route path="/cadastro" exact component={Cadastro} />
                <Route path="/anuncios/page/:page" exact component={Anuncios} />
                <Route path="/anuncios/:id" exact component={AnuncioDetail}/>

                {/* <Route path="/admin" exact component={LoginAdmin}/> */}

                {/* transformar em PrivateRoute depois */}
                {/* <Route path="/admin/anuncios/page/:page" exact component={AnunciosList}/>
                <Route path="/admin/anuncio/edit/:id" exact component={AnuncioEdit}/> */}
                {/*<Route path="/admin/anuncios/page/:page" exact component={Anuncios} />*/}
                {/* <Route path="/admin/usuarios" exact component={UsuariosList}/>
                <Route path="/admin/usuario/edit/:id" exact component={UsuarioEdit}/> */}

                <PrivateRoute path="/meus-anuncios/page/:page" exact component={MeusAnuncios}/>
                <PrivateRoute path="/meus-anuncios/:id" exact component={MeuAnuncioDetail}/>
                <PrivateRoute path="/novo-anuncio" exact component={NovoAnuncio}/>
                <PrivateRoute path="/perfil" exact component={Perfil}/>
                <PrivateRoute path="/perfil/endereco" exact component={ListEndereco}/>
                <PrivateRoute path="/perfil/endereco/:id" exact component={EditEndereco}/>
                <PrivateRoute path="/perfil/novo-endereco" exact component={NewEndereco}/>
                <PrivateRoute path="/perfil/contato" exact component={ListContato}/>
                <PrivateRoute path="/perfil/contato/:id" exact component={EditContato}/>
                <PrivateRoute path="/perfil/novo-contato" exact component={NewContato}/>
                <PrivateRoute path="/anuncios/edit/:id" exact component={EditAnuncio}/>
                <PrivateRoute path="/perfil/edit/" exact component={EditPerfil}/>
                <PrivateRoute path="/perfil/senha/edit/" exact component={EditSenha}/>

            </Switch>
        </BrowserRouter>
    )
}