import React  from 'react'

import { Button } from 'react-materialize';
import { Link } from 'react-router-dom';
import './style.css'
import Header from '../../../components/Header';


export default function Escolha() {

    return(

        <div id="cadastro-escolha">
            <Header></Header>
            <div className="container">
                <div className="row">
                    <div className="col s12 l6">
                        <div className="container-login z-depth-5">
                            <h5 className="title-cards">Cadastre-se como comprador</h5>
                            <div className="row">
                                <p>Você é um comprador regular de alimentos ou tem um comércio?</p>
                                <Link to="/cadastro/comprador/usuario">
                                    <Button
                                        node="button"
                                        type="submit"
                                        waves="light"
                                        style={{
                                            float: 'right',
                                            width: "100%",
                                        }}
                                    >
                                        Cadastro Comprador
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col s12 l6">
                        <div className="container-login z-depth-5">
                            <h5 className="title-cards">Cadastre-se como Agricultor</h5>
                            <div className="row">
                                <p>É Produtor Rural ou faz parte de uma Associação ou de Cooperativas de Produtores?</p>
                                <Link to="/cadastro/produtor/usuario">
                                    <Button
                                        node="button"
                                        type="submit"
                                        waves="light"
                                        style={{
                                            float: 'right',
                                            width: "100%",
                                        }}
                                        >
                                        Cadastro Agricultor
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}