import React  from 'react'
import { Link } from 'react-router-dom';
import logoManuca from './../../assets/manuca-logo.png'

import './style.css'

export default function HeaderHome() {
    
    function handleLogin(){
      window.location = "/login"
    }

    return(
      <div className="fixed navbar">
          <nav class="menu" role="navigation">
          <div class="nav-wrapper">
            <a id="logo-container" href="/" class="brand-logo"><img src={logoManuca} alt="logo manuca Ms" className="image-logo-manuca"></img></a>
            <ul class="right hide-on-med-and-down">
              <li><Link to="/anuncios/page/1">Anúncios</Link></li>
              <li><a href="#how-it-works">Como funciona?</a></li>
              <li><a href="#about-us">Sobre Nós</a></li>
              <li><a href="#contact">Contato</a></li>
              <li><Link onClick={handleLogin}><i class="material-icons right">person</i>Entrar</Link></li>
            </ul>
      
            <ul id="nav-mobile" class="sidenav">
              <li><Link to="/anuncios/page/1">Anúncios</Link></li>
              <li><a href="#about-us">Sobre Nós</a></li>
              <li><a href="#how-it-works">Como funciona?</a></li>
              <li><a href="#contact">Contato</a></li>
              <li><Link onClick={handleLogin}><i class="material-icons right">person</i>Entrar</Link></li>
            </ul>
            <Link data-target="nav-mobile" class="sidenav-trigger"><i class="material-icons">menu</i></Link>
          </div>
        </nav>
      </div>
    )
}