import React, {useState }  from 'react'
import api from '../../../services/api'
import tokenServer from '../../../services/tokenServer'

import Preloader from '../../../components/Preloader'
import ModalError from '../../../components/ModalError'
import HeaderProfile from '../../../components/HeaderProfile';

import { Container, TextInput, Row, Col, Button } from 'react-materialize';

import './style.css'

export default function Perfil() {
    const [load, setLoad] = useState(false);
    const [modal, setModal] = useState(false);
    const [erro, setErro] = useState('');
    const [url, setUrl] = useState('');
    const [oldPassword, setOldPassword] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const user_id = localStorage.getItem('user_id')
    const token = localStorage.getItem('token')

    async function handleEdit(event){
        event.preventDefault();
        setLoad(true)
        setModal(false)
        if(password === password2){
            try {
                const access_token = await tokenServer.getToken()
                const data= { oldPassword, password }
                await api.put(`users/${user_id}`,data ,{ headers: {
                    authorizationuser: token,
                    Authorization: `Bearer ${access_token}`,
                }
                })
                setUrl("/perfil")
                setErro("Senha Atualizada")
                setLoad(false)
                setModal(true)
            } catch (error) {
                setUrl("/perfil/senha/edit")
                setErro("Erro, por favor verifique se os campos estão preenchidos corretamente")
            }
        }else{
            setErro("Erro, senhas não compativeis")
        }
        setLoad(false)
        setModal(true)
    }

    return(
        <div id="perfil-edit">
            <Preloader load={load}/>
            <ModalError show={modal} error={erro} url={url}/>
            <HeaderProfile title="Editar Senha"/>
            <div className="wrapper">
                <Container className="z-depth-5">
                    <form onSubmit={handleEdit}>
                        <h5>Dados</h5>
                        <Row>
                            <Col m={4} s={12}>
                                <TextInput
                                    noLayout={true}
                                    id="TextInput-4"
                                    label="Senha Atual"
                                    minlength="6"
                                    maxlength="16"
                                    password
                                    value={oldPassword}
                                    onChange={event => setOldPassword(event.target.value)}
                                />
                            </Col>
                            <Col m={4} s={12}>
                                <TextInput
                                    noLayout={true}
                                    id="TextInput-4"
                                    label="Nova senha"
                                    minlength="6"
                                    maxlength="16"
                                    password
                                    value={password}
                                    onChange={event => setPassword(event.target.value)}
                                />
                            </Col>
                            <Col m={4} s={12}>
                                <TextInput
                                    noLayout={true}
                                    id="TextInput-4"
                                    minlength="6"
                                    maxlength="16"
                                    label="Confirmar nova senha"
                                    password
                                    value={password2}
                                    onChange={event => setPassword2(event.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Button
                                    node="button"
                                    waves="light"
                                    type="submit"
                                    style={{
                                        float: 'right'
                                    }}
                            >
                                    Atualizar
                            </Button>
                        </Row>
                    </form>
                </Container>
            </div>
        </div>
        
    )
}