import React  from 'react'

import './style.css'

import { Modal, Button } from 'react-materialize';

export default function Modalerror(props) {

    function handleClick() {
        if(props.url){
            window.location.href = props.url
        }
    }

    if(props.show){
        return(
            <Modal
                actions={[
                    <Button onClick={handleClick} flat modal="close" node="button" waves="green">Fechar</Button>
                ]}
                bottomSheet={false}
                fixedFooter={false}
                header={props.title}
                id="Modal-0"
                open={true}
                options={{
                    dismissible: true,
                    endingTop: '10%',
                    inDuration: 250,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    opacity: 0.5,
                    outDuration: 250,
                    preventScrolling: true,
                    startingTop: '4%'
                }}
                >
                <p>
                    {props.error}
                </p>
            </Modal>
            
        )
    }
    return <div></div>
    
}