import React, { useState, useEffect }  from 'react'
import api from './../../../services/api'
import tokenServer from '../../../services/tokenServer'
import { useParams } from 'react-router-dom';

import Preloader from './../../../components/Preloader'
import ModalError from './../../../components/ModalError'
import { Container, TextInput, Row, Col, Button } from 'react-materialize';
import HeaderProfile from './../../../components/HeaderProfile';

import './style.css'

export default function EditEndereco() {
    const params = useParams()
    const [load, setLoad] = useState(true);
    const [modal, setModal] = useState(false);
    const [erro, setErro] = useState('');
    const [url, setUrl] = useState('');
    const [postal_code, setPostal_code] = useState('');
    const [number, setNumber] = useState('');
    const [address, setAddress] = useState('');
    const [district, setDistrict] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const token = localStorage.getItem('token')

    async function loadAddress(){
        try {
            const access_token = await tokenServer.getToken()
            const response = await api.get(`address/${params.id}`,{ headers: {
                    authorizationuser: token,
                    Authorization: `Bearer ${access_token}`,
                }
            })
            setPostal_code(response.data.postal_code)
            setAddress(response.data.address)
            setNumber(response.data.number)
            setDistrict(response.data.district)
            setState(response.data.state)
            setCity(response.data.city)
            
        } catch (error) {
        }
        setLoad(false)
    }

    useEffect(() => {
        loadAddress()
    }, [token, params.id])

    async function handleEditEndereco(event) {
        event.preventDefault();
        setModal(false)
        setLoad(true)
        const access_token = await tokenServer.getToken()
        const data = { postal_code, address, number, city, district, state }
        try {
            await api.put(`address/${params.id}`, data ,{ headers: {
                authorizationuser: token,
                Authorization: `Bearer ${access_token}`,
            }
            })
            setUrl('/perfil/endereco')
            setErro("Endereço Atualizado")
            setLoad(false)
            setModal(true)
        } catch (erro) {
            setErro("Erro, por favor verifique se os campos estão preenchidos corretamente")
            if(erro.response.data.exp === "token expired"){
                tokenServer.refreshToken()
                window.location.reload()
            }
        }
        setLoad(false)
        setModal(true)
    }

    return(
        <div id="edit-endereco">
            <Preloader load={load}/>
            <ModalError show={modal} error={erro} url={url} />
            <HeaderProfile title="Editar Endereço"/>
            <div className="wrapper">
            <Container className="z-depth-5">
            <form onSubmit={handleEditEndereco}>
                <h5>Endereço</h5>
                <Row>
                    <Col m={4} s={12}>
                        <TextInput 
                            noLayout={true}
                            id="TextInput-4"
                            label="CEP"
                            value={postal_code}
                            onChange={event => setPostal_code(event.target.value)}
                        />
                    </Col>
                    <Col m={6} s={12}>
                        <TextInput 
                            noLayout={true}
                            id="TextInput-4"
                            label="Rua"
                            value={address}
                            onChange={event => setAddress(event.target.value)}
                        />
                    </Col>
                    <Col m={2} s={12}>
                        <TextInput 
                            noLayout={true}
                            id="TextInput-4"
                            label="Numero"
                            value={number}
                            onChange={event => setNumber(event.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col m={4} s={12}>
                        <TextInput 
                            noLayout={true}
                            id="TextInput-4"
                            label="Bairro"
                            value={district}
                            onChange={event => setDistrict(event.target.value)}
                        />
                    </Col>
                    <Col m={4} s={12}>
                        <TextInput 
                            noLayout={true}
                            id="TextInput-4"
                            label="UF"
                            value={state}
                            onChange={event => setState(event.target.value)}
                        />
                    </Col>
                    <Col m={4} s={12}>
                        <TextInput 
                            noLayout={true}
                            id="TextInput-4"
                            label="Cidade"
                            value={city}
                            onChange={event => setCity(event.target.value)}
                        />
                    </Col>
                    
                </Row>
                <Row>
                    <Button
                            node="button"
                            type="submit"
                            waves="light"
                            style={{
                                float: 'right'
                            }}
                        >
                        Atualizar
                    </Button>
                </Row>
            </form>
            
            </Container>
            </div>
            
        </div>
        
    )
}