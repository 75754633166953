import React  from 'react'
import { Link } from 'react-router-dom';
// import 'materialize-css';
import { Dropdown, Icon, Navbar, NavItem } from 'react-materialize';

import logoManuca from './../../assets/manuca-logo.png'
import './style.css'

export default function HeaderProfile(props) {

    function HandleLogout(){
      localStorage.clear()
      window.location.replace('/');
    }

    return(
        <div className="wrapper">
          <Navbar
              className="nav"
              alignLinks="right"
              brand={ <a id="logo-container" href="/" class="brand-logo"><img src={logoManuca} alt="logo manuca Ms" className="image-logo-manuca"></img></a>}
              leftLogo
              fixed
              id="mobile-nav"
              menuIcon={<Icon>menu</Icon>}
              options={{
                  draggable: true,
                  edge: 'left',
                  inDuration: 250,
                  onCloseEnd: null,
                  onCloseStart: null,
                  onOpenEnd: null,
                  onOpenStart: null,
                  outDuration: 200,
                  preventScrolling: true
              }}
              >
                  <Dropdown
                      id="anun"
                      options={{
                        alignment: 'left',
                        autoTrigger: true,
                        closeOnClick: true,
                        constrainWidth: true,
                        container: null,
                        coverTrigger: false,
                        hover: false,
                        inDuration: 150,
                        onCloseEnd: null,
                        onCloseStart: null,
                        onOpenEnd: null,
                        onOpenStart: null,
                        outDuration: 250,
                      }}
                      trigger={<a href="#anun">Anúncios{' '}<Icon right>arrow_drop_down</Icon></a>}
                  >
                  <Link to="/anuncios/page/1">
                    Todos os Anúncios
                  </Link>
                  {/* <Divider /> */}
                  <Link to="/meus-anuncios/page/1">
                      Meus Anúncios
                  </Link>
                  {/* <Divider /> */}
                  <Link to="/novo-anuncio">
                      Novo Anúncio
                  </Link>
                </Dropdown>
              <Dropdown
                id="ender"
                options={{
                  alignment: 'left',
                  autoTrigger: true,
                  closeOnClick: true,
                  constrainWidth: true,
                  container: null,
                  coverTrigger: false,
                  hover: false,
                  inDuration: 150,
                  onCloseEnd: null,
                  onCloseStart: null,
                  onOpenEnd: null,
                  onOpenStart: null,
                  outDuration: 250,
                }}
                trigger={<a href="#ender">Endereço{' '}<Icon right>arrow_drop_down</Icon></a>}
              >
                <Link to="/perfil/endereco">
                    Endereços
                </Link>
                {/* <Divider /> */}
                <Link to="/perfil/novo-endereco">
                    Novo Endereço
                </Link>
              </Dropdown>
              <Dropdown
                id="conta"
                options={{
                  alignment: 'left',
                  autoTrigger: true,
                  closeOnClick: true,
                  constrainWidth: true,
                  container: null,
                  coverTrigger: false,
                  hover: false,
                  inDuration: 150,
                  onCloseEnd: null,
                  onCloseStart: null,
                  onOpenEnd: null,
                  onOpenStart: null,
                  outDuration: 250,
                }}
                trigger={<a href="#conta">Telefones{' '}<Icon right>arrow_drop_down</Icon></a>}
              >
                <Link to="/perfil/contato">
                    Telefones
                </Link>
                {/* <Divider /> */}
                <Link to="/perfil/novo-contato">
                    Novo telefone
                </Link>
              </Dropdown>
               <Dropdown
                id="per"
                options={{
                  alignment: 'left',
                  autoTrigger: true,
                  closeOnClick: true,
                  constrainWidth: true,
                  container: null,
                  coverTrigger: false,
                  hover: false,
                  inDuration: 150,
                  onCloseEnd: null,
                  onCloseStart: null,
                  onOpenEnd: null,
                  onOpenStart: null,
                  outDuration: 250,
                }}
                trigger={<a href="#per"><Icon>account_circle arrow_drop_down</Icon></a>}
              >
                <Link to="/perfil">
                    Perfil
                </Link>
                {/* <Divider /> */}
                <NavItem className=""
                onClick={HandleLogout}
                icon={<Icon>arrow_back</Icon>}
                waves
              >
                  Sair
              </NavItem>
              </Dropdown>
              
          </Navbar>
      </div>
    )
}