import React, { useState, useEffect }  from 'react'
import api from '../../../services/api'
import tokenServer from '../../../services/tokenServer'
import { Link} from 'react-router-dom';


import HeaderProfile from '../../../components/HeaderProfile';
import Preloader from './../../../components/Preloader'
import { Container, TextInput, Row, Col, Button } from 'react-materialize';

import './style.css'

export default function ListEndereco() {
    const [load, setLoad] = useState(true);
    const [phones, setPhones] = useState([])
    const token = localStorage.getItem('token')

    async function loadData(){
        try {
            const access_token = await tokenServer.getToken()
            const response = await api.get('phones',{ headers: {
                    authorizationuser: token,
                    Authorization: `Bearer ${access_token}`,
                }
            })
            setPhones(response.data)
        } catch (error) {
        }
        setLoad(false)
    }
    

    useEffect(() => {
        loadData()
    }, [token])

    async function handleDeleteContato(id){
        try {
            const access_token = await tokenServer.getToken()
            await api.delete(`phones/${id}`,{ headers: {
                authorizationuser: token,
                Authorization: `Bearer ${access_token}`,
            }
            })
            const newList = phones.filter(phone => phone.id !== id)
            setPhones(newList)
        } catch (error) {
            alert("Erro, ao excluir")
        }
        
    }


    return(
        <div id="list-contatos">
            <Preloader load={load}/>
            <HeaderProfile title="Contatos" />
            <div className="wrapper">
            <Container>
                    
            {
                phones.map((phone,index) => (
                    <div key={phone.id} id="contato">
                        <div className="container-contato z-depth-5">
                        <h5> Telefone {index + 1}</h5>
                        <Row>
                            <Col m={6} s={12}>
                                <TextInput
                                    disabled
                                    icon="phone"
                                    noLayout={true}
                                    id="TextInput-4"
                                    label="Telefone"
                                    value={phone.phone_number}
                                />
                            </Col>
                            <Col m={6} s={12}>
                                <TextInput
                                    disabled
                                    noLayout={true}
                                    id="TextInput-4"
                                    label="Tipo"
                                    value={phone.phone_type}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Button
                                    className="button red lighten-1"
                                    node="button"
                                    type="submit"
                                    waves="light"
                                    onClick={()=> handleDeleteContato(phone.id)}
                                    style={{
                                        float: 'right',
                                        width: "49%",
                                    }}
                                    >
                                    Excluir
                            </Button>
                            <Link to={`contato/${phone.id}`}>
                                <Button
                                        node="button"
                                        type="submit"
                                        waves="light"
                                        style={{
                                            float: 'left',
                                            width: "49%",
                                        }}
                                        >
                                        Editar
                                </Button>
                            </Link>
                        </Row>
                        </div>
                    </div>
                ))
            }
            
            </Container>
            </div>
            <div className="container">
                <Link to="novo-contato">
                    <Button
                            node="button"
                            type="submit"
                            waves="light"
                            style={{
                                float: 'right',
                                width: "100%",
                                margin: "40px auto",
                                // marginBottom: "50px",
                            }}
                            >
                            Novo
                    </Button>
                </Link>
            </div>
            
        </div>
        
    )
}