import axios from 'axios'
import qs from 'qs'
require('dotenv/config');

export default {
    async getToken(){
        try {
            const token = localStorage.getItem('access_token')
            // console.log("token : " + token)
            await axios.get(`${process.env.REACT_APP_SERVER_URL}/ads/all`, {
                headers:{
                    Authorization: `Bearer ${token}`,
                }
            })
            // console.log("Token segue valido")
            return token
        } catch (error) {
            // console.log("entrou no error")
            const response = await axios.post(process.env.REACT_APP_URL_TOKEN, qs.stringify({
                client_id: process.env.REACT_APP_CLIENT_ID,
                grant_type: process.env.REACT_APP_GRANT_TYPE,
                client_secret: process.env.REACT_APP_CLIENT_SECRET,
            }), {
                headers:{'Content-Type': 'application/x-www-form-urlencoded'}
            })
            // console.log("response : " + response.data.access_token)
            localStorage.setItem('access_token', response.data.access_token)
            return response.data.access_token
        }
    },
}
