import React, {useEffect, useState}  from 'react'
import api from '../../../services/api'
import tokenServer from '../../../services/tokenServer'

import { useParams } from 'react-router-dom';
import HeaderProfile from '../../../components/HeaderProfile';
import Preloader from '../../../components/Preloader'
import HeaderAnuncio from '../../../components/HeaderAnuncios'
import Img from '../../../assets/produto-sem-imagem-1.gif'

import { Icon, Button } from 'react-materialize';


import './style.css'

export default function AnuncioDetail() {
    const params = useParams()
    const [load, setLoad] = useState(true);
    const [usuario, setUsuario] = useState([])
    const [address, setAddress] = useState([])
    const [telefone, setTelefone] = useState([])
    const [image, setImage] = useState(Img)
    const [ad_type, setAd_type] = useState('')
    const [product_name, setProduct_name] = useState('')
    const [amount, setAmount] = useState('')
    const [amount_type, setAmount_type] = useState('')
    const [frequency, setFrequency] = useState('')
    const [description, setDescription] = useState('')
    const [product_type, setProduct_type] = useState('Frutas')
    const token = localStorage.getItem('token')
    
    async function loadAd(){
        try {
            const access_token = await tokenServer.getToken()
            const response = await api.get(`ads/${params.id}`,{ headers: {
                authorizationuser: token,
                Authorization: `Bearer ${access_token}`,
            }})
            setProduct_name(response.data.product_name)
            setAmount(response.data.amount)
            setFrequency(response.data.frequency)
            setDescription(response.data.description)
            setProduct_type(response.data.product_type)
            setAmount_type(response.data.amount_type)
            setAd_type(response.data.ad_type)
            setAddress(response.data.address)
            setUsuario(response.data.user)
            setTelefone(response.data.user.phone)
            if(response.data.image_url){
                setImage(response.data.image_url)
            }
            window.scrollTo(0, 0)
            setLoad(false)
        } catch (error) {
            
        }
    }

    useEffect(() => {
       loadAd() 
    }, [params.id])

    function HeaderAnuncioDetail() {
        if(token){
            return <HeaderProfile title={ad_type}/>
        }else {
            return <HeaderAnuncio title={ad_type}/>
        }
    }

    return(
        <div id="anuncio-detail">
            <Preloader load={load}/>
            <HeaderAnuncioDetail />
                <div className="container">
                    <div className="card-detail">

                            <div className="row">
                                <div className="title-top">
                                    <h3>{product_name}</h3>
                                    <a className="share" href={`https://api.whatsapp.com/send?text=${description} https://manuca.semagro.ms.gov.br/anuncios/${params.id}`}>
                                        <Icon>share</Icon>
                                    </a>
                                </div>
                                
                            </div>
                            <div className="row">
                                <img src={image} className="img" ></img>                 
                            </div>
                            <div className="row">
                                <div className="title-2">
                                    <h3>Descricao do Produto</h3>
                                </div>
                            </div>
                            <div className="row">
                                    <div className="container-description">
                                        <p><strong>Tipo de Anúncio:</strong> {ad_type}</p>
                                        <p><strong>Nome: </strong> {product_name}</p>
                                        <p><strong>Tipo: </strong> {product_type}</p>
                                        <p><strong>Descrição: </strong> {description}</p>
                                        <p><strong>Quantidade: </strong> {amount + ' ' + amount_type} </p>
                                        <p><strong>Frequencia: </strong> {frequency}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="title">
                                    <h3>Dados do Anunciante</h3>
                                </div>
                            </div>
                            <div className="row">
                                    <div className="container-description">
                                        <p><strong>Nome: </strong> {usuario.name}</p>
                                        <p><strong>Propriedade: </strong> {usuario.property_name}</p>
                                        <p><strong>E-mail: </strong> {usuario.email}</p>
                                        {
                                            telefone.map(telefone => (
                                                <div key={telefone.id}>
                                                    <p><strong>Telefone {telefone.phone_type}: </strong> {telefone.phone_number}</p>
                                                </div>

                                            ))
                                        }
                                        <p><strong>Localização: </strong> {address.address + ", " + address.number + ", " + address.district + ", " + address.city + ", " + address.state}</p>
                                        

                                </div>
                            </div>
                    </div>
                </div>
            </div>
    )
}