import React, { useState }  from 'react'
import api from '../../../services/api'
import tokenServer from '../../../services/tokenServer'

import Cep from 'cep-promise';
import Preloader from './../../../components/Preloader'
import ModalError from './../../../components/ModalError'
import { Container, TextInput, Row, Col, Button } from 'react-materialize';
import HeaderProfile from '../../../components/HeaderProfile';

import './style.css'

export default function NewEndereco() {
    const [load, setLoad] = useState(false);
    const [modal, setModal] = useState(false);
    const [erro, setErro] = useState('');
    const [url, setUrl] = useState('');
    const [postal_code, setPostal_code] = useState('');
    const [address, setAddress] = useState('');
    const [number, setNumber] = useState('');
    const [district, setDistrict] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const token = localStorage.getItem('token')


    async function handleCep(cep){
        setPostal_code(cep)
        if(postal_code.length === 7){
            try {
                const data  = await Cep(cep)
                setAddress(data.street)
                setDistrict(data.neighborhood)
                setState(data.state)
                setCity(data.city)
            } catch (error) {
            }
        }
    }

    async function handleNewEndereco(event) {
        event.preventDefault();
        setLoad(true)
        setModal(false)
        const data = { postal_code, address, number, city, district, state }
        try {
            const access_token = await tokenServer.getToken()
            await api.post('address/', data, { headers: {
                    authorizationuser: token,
                    Authorization: `Bearer ${access_token}`,
                }
            })
            setUrl('/perfil/endereco')
            setErro("Endereço Criado")
        } catch (erro) {
            setErro("Erro, por favor verifique se os campos estão preenchidos corretamente")
        }
        setLoad(false)
        setModal(true)
    }

    return(
        <div id="new-endereco">
            <Preloader load={load}/>
            <ModalError show={modal} error={erro} url={url} />
            <HeaderProfile title="Novo Endereço" />
            <div className="wrapper">
            <Container className="z-depth-5">
            <form onSubmit={handleNewEndereco}>
                <h5>Novo Endereco</h5>
                <Row>
                    <Col m={2} s={12}>
                        <TextInput 
                            required
                            noLayout={true}
                            id="TextInput-4"
                            label="CEP"
                            value={postal_code}
                            onChange={event => handleCep(event.target.value)}
                        />
                    </Col>
                    <Col m={6} s={12}>
                        <TextInput 
                            required
                            noLayout={true}
                            id="TextInput-4"
                            label="Rua"
                            value={address}
                            onChange={event => setAddress(event.target.value)}
                        />
                    </Col>
                    <Col m={4} s={12}>
                        <TextInput 
                            required
                            noLayout={true}
                            id="TextInput-4"
                            label="Numero"
                            value={number}
                            onChange={event => setNumber(event.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col m={4} s={12}>
                        <TextInput 
                            required
                            noLayout={true}
                            id="TextInput-4"
                            label="Bairro"
                            value={district}
                            onChange={event => setDistrict(event.target.value)}
                        />
                    </Col>
                    <Col m={4} s={12}>
                        <TextInput 
                            required
                            noLayout={true}
                            id="TextInput-4"
                            label="UF"
                            value={state}
                            onChange={event => setState(event.target.value)}
                        />
                    </Col>
                    <Col m={4} s={12}>
                        <TextInput 
                            required
                            noLayout={true}
                            id="TextInput-4"
                            label="Cidade"
                            value={city}
                            onChange={event => setCity(event.target.value)}
                        />
                    </Col>
                    
                </Row>
                <Row>
                    <Button
                            node="button"
                            type="submit"
                            waves="light"
                            style={{
                                float: 'right'
                            }}
                        >
                        Cadastrar
                    </Button>
                </Row>
            </form>
            
            </Container>
            </div>
        </div>
        
    )
}