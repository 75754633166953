import React, { useState} from 'react'
import { Link } from 'react-router-dom';

import tokenServer from '../../services/tokenServer'
import api from '../../services/api'

import HeaderHome from './../../components/HeaderHome';
import ImgVerduras from './../../assets/foto2.jpg'
import ImgCampo from './../../assets/foto3.jpg'
import logoEstado from './../../assets/logoEstado.png'
import Ufgd from './../../assets/ufgd.png'
import Sgi from './../../assets/logomarca-png.png'
import ImgFamilia from './../../assets/summer.jpg'

import Preloader from './../../components/Preloader'
import ModalError from './../../components/ModalError'

import './style.css'

export default function Home() {
  const [load, setLoad] = useState(false);
  const [modal, setModal] = useState(false);
  const [erro, setErro] = useState('');
  const [ name, setName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ phone, setPhone ] = useState('');
  const [ message, setMessage ] = useState('');


  function mtel(v){
    v=v.replace(/\D/g,"");             //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d)/g,"($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    v=v.replace(/(\d)(\d{4})$/,"$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
    return v;
  }

  function handlePhone(value) {
      setPhone(mtel(value))
  }

  async function handleContato(event){
    event.preventDefault();
    setLoad(true)
    setModal(false)
    try {
      const access_token = await tokenServer.getToken()
      const data = { name, email, phone, message }
      await api.post('contact', data ,{ headers: {
        Authorization: `Bearer ${access_token}`,
      }
    })
      setErro("Mensagem enviada")
      window.scrollTo(0, 0) 
    } catch (error) {
      setErro("Erro, por favor verifique se os campos estão preenchidos corretamente")
    }
    setLoad(false);
    setModal(true);
    
  }

  return (
    <div id="home">
      <Preloader load={load}/>
      <ModalError show={modal} error={erro}/>
      <HeaderHome />
      <div>
        <div id="index-banner" className="parallax-container valign-wrapper">
          <div className="section no-pad-bot">
            <img src={logoEstado} alt="logo estado Ms" className="image-logo-estado"></img>
            <div className="container">
              <h1 className="header center">MANUCÃ</h1>
              <div className="row center">
                <h5 className="header col s12 light">Ligando a produção da agricultura familiar à cidade de
                forma rápida e sustentável. Participe dessa plataforma sulmatogrossense, divulgue seus produtos online
                e sem custo. </h5>
              </div>
              <div className="row center">
                <Link to="/cadastro/termos-uso" id="download-button" className="btn-large waves-effect pulse button">
                  Cadastre-se
                </Link>
              </div>
            </div>
          </div>
          <div className="parallax"><img src={ImgVerduras} alt="FOTO 3" /></div>

        </div>
        <div className="button-up">
          <a className="btn-floating btn-large waves-effect waves-light red right" href="#index-banner"><i
            className="material-icons">keyboard_arrow_up</i></a>
        </div>

        <div id="how-it-works">
          <div className="container">
            <div className="section">
              <h4 className="center">Como funciona?</h4>
              <iframe id="video" src="https://www.youtube.com/embed/elCy-X9oFbA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <div className="row">
                <div className="col s12 l6">
                  <div className="icon-block">
                    <h2 className="center green-text"><i className="material-icons">eco</i></h2>
                    <h5 className="center">Agricultor</h5>

                    <p className="center-align">
                      <span className="question">
                        É Produtor Rural ou faz parte de uma Associação ou de
                        Cooperativas de Produtores?
                      </span>
                      <br />
                      <a href="/cadastro/produtor/usuario">Cadastre-se aqui</a>.
                      Acesse o link de cadastro disponível no inicio da página, insira todas as informações solicitadas.
                      <br />
                      A Secretaria de Estado de Meio Ambiente, Desenvolvimento Econômico, Produção e Agricultura Familiar
                      (<a href="http://www.semagro.ms.gov.br">SEMAGRO</a>) é responsável pelo desenvolvimento e manutenção da plataforma. Não sendo responsável pelos
                      produtos e negócios realizados nesta, conforme os Termos e Condições e Políticas de Privacidade.
                    </p>
                    <p className="center-align">
                      <span className="question">
                        É necesário pagar para se cadastrar?
                      </span>
                      <br />
                      Não, o uso da plataforma é totalmente gratuita a todos.
                    </p>
                    <p className="center-align">
                      <span className="question">
                        O que posso anunciar?
                      </span>
                      <br />
                      Tudo relacionado a produção e comercialização de frutas, hortaliças, mudas e plantas ornamentais Não é liberado anúncios de produtos que não sejam provenientes de produção rural como: móveis, utensílios, eletrodomésticos, entre outros.
                    </p>
                    <p className="center-align">
                      <span className="question">
                        Preciso me cadastrar cada vez que vou anunciar?
                      </span>
                      <br />
                      Não, é preciso se cadastrar apenas uma vez para utilizar a plataforma.
                    </p>
                    <p className="center-align">
                      <span className="question">
                        Há limite de produtos a serem anunciados?
                    </span><br />
                    Não, a quantidade de produtos anunciados é livre de acordo com os critérios do vendedor.</p>
                  </div>
                </div>

                <div className="col s12 l6">
                  <div className="icon-block">
                    <h2 className="center green-text"><i className="material-icons">shopping_cart</i></h2>
                    <h5 className="center">Comprador</h5>

                    <p className="center-align">
                      <span className="question">
                        Você é um comprador regular de alimentos?
                      </span>
                      <br />
                      <a href="/cadastro/comprador/usuario">Cadastre-se aqui</a>.
                      Acesse o link de cadastro disponível no inicio da página, insira todas as informações solicitadas.
                      <br />
                      A Secretaria de Estado de Meio Ambiente, Desenvolvimento Econômico, Produção e Agricultura Familiar
                      (<a href="http://www.semagro.ms.gov.br">SEMAGRO</a>) é responsável pelo desenvolvimento e manutenção da plataforma. Não sendo responsável pelos
                      produtos e negócios realizados nesta, conforme os Termos e Condições e Políticas de Privacidade.
                    </p>
                    <p className="center-align">
                      <span className="question">
                        É necesário pagar para se cadastrar?
                      </span>
                      <br />
                      Não, o uso da plataforma é totalmente gratuita a todos.
                    </p>
                    <p className="center-align">
                      <span className="question">
                        O que posso anunciar?
                      </span>
                      <br />
                      Tudo relacionado a produção e comercialização de frutas, hortaliças, mudas e plantas ornamentais.
                      Não é liberado anúncios de produtos que não sejam provenientes de produção rural como: móveis,
                      utensílios, eletrodomésticos, entre outros.
                    </p>
                    <p className="center-align">
                      <span className="question">
                        Preciso me cadastrar cada vez que vou anunciar?
                      </span>
                      <br />
                      Não, é preciso se cadastrar apenas uma vez para utilizar a plataforma.
                    </p>
                  </div>
                </div>
              </div>
            </div>
             
          </div>

          
        </div>


        <div id="home-anuncios"  className="parallax-container valign-wrapper">
          <div  className="section no-pad-bot">
            <div className="container">
              <div className="row center">
                <h5 className="header col s12">Faça parte do MANUCÃ e cadastre as suas ofertas e demandas.</h5>
              </div>
              <div className="row center">
                <p>Confira os anúncios:</p>
                <Link to="/anuncios/page/1" id="download-button" className="btn-large waves-effect pulse button">
                  Anúncios
                </Link>
              </div>
            </div>
          </div>
          <div className="parallax"><img src={ImgCampo} alt="FOTO 3" /></div>
        </div>

        <div id="about-us">
          <div className="container">
            <div className="section">
              <div className="row">
                <div className="col s12 center">
                  <h3><i className="mdi-content-send"></i></h3>
                  <h4>Sobre Nós</h4>
                  <p className="light">A nova plataforma de conexão que aproxima produtores rurais da agricultura familiar e
                  consumidores elaborada pela Secretaria de Estado de Meio Ambiente, Desenvolvimento Econômico, Produção e
                  Agricultura Familiar (<a href="http://www.semagro.ms.gov.br">SEMAGRO</a>) é uma forma rápida e eficaz de conectar o campo à cidade nesse momento de
                  crise causada pela pandemia do COVID-19.</p>
                  <p className="light">Com a plataforma, produtores rurais acessam novos canais de venda, e os compradores ampliam
                  a sua rede de fornecedores e com isso as empresas de logística pode participar viabilizando e otimizando as
                  entregas.
                  </p>
                  <p>
                    Usamos a tecnologia para conectar o agricultor no campo ao cliente comercial na cidade seja ele bar, restaurante,
                    quitanda, mercado ou hipermercado. Queremos que o agricultor tenha como acessar este mercado e ou cliente comercial para poder vender seus produtos que
                    neste momento está com dificuldade de escoamento da produção.
                  </p>
                  <p>
                    Dentro da plataforma não é realizado e-commerce, somente é permitido disponibilizar anúncios de oferta e demanda.
                    É um local virtual onde agricultores familiares e consumidores podem se encontrar e anunciar tudo relacionado a
                    produção e comercialização de frutas, hortaliças, mudas e plantas ornamentais – Gratuitamente.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div id="apoiadores" className="parallax-container valign-wrapper">
          <div className="container">
            <div className="section">
              <h4>Apoiadores</h4>
                <div className="row">
                  <div className="col s12 m6 l6">
                      <img className="apoiadores-images" src={Ufgd}></img>
                  </div>
                  <div className="col s12 m6 l6">
                      <img className="apoiadores-images" src={Sgi}></img>
                  </div>
                </div>
            </div>
          </div>
          <div className="parallax"><img src={ImgFamilia} alt="FOTO 4" /></div>
        </div>
        <div id="contact">
          <div className="container">
            <div className="section">
              <div className="row">
                <h4 className="center">Contato</h4>
                <form className="col s12 m6 offset-m3" onSubmit={handleContato}>
                  <div className="row">
                    <div className="input-field col s12">
                      <i className="material-icons prefix">account_circle</i>
                      <input id="name" type="text" className="validate" value={name} onChange={event => setName(event.target.value) } required/>
                      <label for="name">Nome</label>
                    </div>

                    <div className="input-field col s12">
                      <i className="material-icons prefix">email</i>
                      <input id="email" type="tel" className="validate" value={email} onChange={event => setEmail(event.target.value) } required/>
                      <label for="email">Email</label>
                    </div>

                    <div className="input-field col s12">
                      <i className="material-icons prefix">phone</i>
                      <input id="phone" type="tel" className="validate" value={phone} onChange={event => handlePhone(event.target.value) } maxlength="15" required/>
                      <label for="phone">Telefone</label>
                    </div>

                    <div className="input-field col s12">
                      <i className="material-icons prefix">message</i>
                      <textarea id="message" className="materialize-textarea" value={message} onChange={event => setMessage(event.target.value) } required></textarea>
                      <label for="message">Mensagem</label>
                    </div>
                    <div className="row col s12 center">
                      <button className="btn btn-large waves-effect waves-light button" type="submit" name="action">Enviar
                  <i className="material-icons right">send</i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        

        <footer className="page-footer">
          <div className="footer-copyright">
            <div className="container center">
              <p>Manucã em guarani significa: vou trabalhar na roça - SEMAGRO © 2020 </p>
            </div>
          </div>
        </footer>
      </div>


    </div>
  )
}