import React  from 'react'
import { Link } from 'react-router-dom';

import { Icon, Navbar } from 'react-materialize';

import './style.css'
import logoManuca from './../../assets/manuca-logo.png'


export default function HeaderAnuncios(props) {

      function handleClick(){
        window.location = "/"
      }
      function handleLogin(){
        window.location = "/login"
      }
      return(
        <Navbar
          className="nav"
          alignLinks="right"
          brand={ <a id="logo-container" href="/" class="brand-logo"><img src={logoManuca} alt="logo manuca Ms" className="image-logo-manuca"></img></a>}
          leftLogo
          fixed
          id="mobile-nav"
          menuIcon={<Icon>menu</Icon>}
          options={{
              draggable: true,
              edge: 'left',
              inDuration: 250,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              outDuration: 200,
              preventScrolling: true
          }}
        >
          <Link onClick={handleClick}><i class="material-icons right">home</i>Início</Link>
          <Link to="/anuncios/page/1">Anúncios</Link>
          <Link onClick={handleLogin}><i class="material-icons right">person</i>Entrar</Link>
        </Navbar>
    )
}



 
