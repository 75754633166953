import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom';

import { Container, Checkbox, Button } from 'react-materialize';
import ModalError from '../../../components/ModalError'

import Header from '../../../components/Header';
import './style.css'

export default function CadProdutor() {
    const [check, setCheck ] = useState(false)
    const [modal, setModal] = useState(false);
    const [erro, setErro] = useState('');
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    function handleButton(){
        setModal(false)
        if(check === true){
            history.push('/cadastro')
        }else{
            setModal(true)
            setErro('Erro, Você não concordou com os termos de uso')
        }
            
    }

    function handleCheckBox(){
        if(check === false){
            setCheck(true)
        }else{
            setCheck(false)
        }
    }

    return(
        <div id="termos-uso">
            <ModalError show={modal} error={erro} url="/cadastro/termos-uso" title="Erro" />
            <Header></Header>
            <Container className="z-depth-5">
                <h2>TERMOS E CONDIÇÕES DE USO</h2>
                <p>
                    A partir do momento em que o Usuário acessa os sistemas, serviços e
                    aplicativos da SEMAGRO, automaticamente estará aderindo e concordando
                    expressamente com as condições dispostas nos termos a seguir bem como,
                    concorda expressamente com o fornecimento de seus dados pessoais para o
                    tratamento e uso compartilhado pela Administração Pública de dados
                    necessários à execução das funcionalidades dos sistemas, serviços e
                    aplicativos, concernentes às atividades precípuas da administração e das
                    políticas públicas previstas em leis e regulamentos ou respaldadas em
                    contrato, convênios ou instrumentos congêneres, observadas as disposições
                    da Lei Federal N. 13.709, DE 14 DE AGOSTO DE 2018.
                </p>
                <h3>I. Sistemas, Serviços e Aplicativos</h3>
                <p>
                    Os sistemas, serviços e aplicativos da SEMAGRO são plataformas que buscam
                    facilitar o acesso a serviços online estão disponibilizados em ambiente web,
                    através do endereço <a href="https://www.semagro.ms.gov.br/">www.semagro.ms.gov.br</a> ou em lojas de aplicativos
                    (Android e IOS), nas quais o download é gratuito para todos.
                </p>
                <h3>II. Definições</h3>
                <p>
                    <em>Para fins deste documento, considera-se:</em>
                    <ul>
                        <li>
                            <b>• Usuário:</b> qualquer pessoa que se cadastre nos sistemas, serviços e
                            aplicativos disponibilizados pela SEMAGRO;
                        </li>
                        <li>
                            <b>• SGI:</b> Superintendência de Gestão da Informação. É o órgão responsável pela
                            gestão, desenvolvimento dos sistemas e aplicativos, segurança das
                            informações coletadas, armazenadas e processadas;
                        </li>
                        <li>
                            <b>• Governo:</b> é o Governo do Estado de Mato Grosso do Sul, responsável por
                            todos os serviços públicos prestados e disponibilizados aos usuários;
                        </li>
                        <li>
                            <b>• Política de Privacidade:</b> documento apartado a este que visa dispor sobre os
                            princípios e regras, seguidas pelos aplicativos e sistemas SEMAGRO, em
                            relação à privacidade dos dados pessoais de seus usuários;
                        </li>
                        <li>
                            <b>• Dados Pessoais:</b> dados relacionados à pessoa natural identificada ou
                            identificável, inclusive números identificativos, dados locacionais ou
                            identificadores eletrônicos quando estes estiverem relacionados a uma pessoa;
                        </li>
                        <li>
                            <b>• Dados Cadastrais:</b> certos dados fornecidos pelo Usuário para fins de
                            cadastro, como nome, CPF, e-mail, entre outros; e
                        </li>
                        <li>
                            <b>• Serviços Públicos:</b> serviços e demais atividades ligadas, em regra, à
                            Administração Pública, proporcionados especialmente para atender as
                            necessidades da população.
                        </li>
                    </ul>
                </p>
                <h3>III. Direitos e Obrigações dos Usuários</h3>
                <p>
                    <em>O Usuário tem o direito de:</em>
                    <ul>
                        <li>
                            • Ter seus dados pessoais armazenados em um banco de dados seguro;
                        </li>
                        <li>
                            • Ter suas respostas e solicitações atendidas no prazo estabelecido pelas Resoluções do Governo do Estado de Mato Grosso do Sul e;
                        </li>
                        <li>
                            • Utilizar os sistemas, serviços e aplicativos SEMAGRO sem qualquer
                            discriminação.
                        </li>
                    </ul>
                    <em>O Usuário se obriga a:</em>
                    <ul>
                        <li>
                            • Respeitar os Termos e Condições de Uso e Política de Privacidade da
                            SEMAGRO;
                        </li>
                        <li>
                            • Verificar periodicamente as atualizações da Política de Privacidade e do
                            presente Termos e Condições de Uso;
                        </li>
                        <li>
                            • Inserir informações pessoais verdadeiras e completas em seu cadastro,
                            sempre as mantendo atualizadas, responsabilizando-se integralmente pela
                            inserção de qualquer informação incorreta, incompleta ou falsa;
                        </li>
                        <li>
                            • Autorizar o uso e reprodução do conteúdo de suas reclamações, sugestões e
                            dúvidas;
                        </li>
                        <li>
                            • Utilizar os sistemas, serviços e aplicativos da SEMAGRO para fins pessoais, não
                            sendo permitida a utilização em nome de terceiros ou com a finalidade de obter
                            informações de terceiros e;
                        </li>
                        <li>
                            • Cuidar de sua senha para acesso pessoal, sempre lembrando que esta é de
                            uso exclusivo e não deve ser repassada a terceiros.
                        </li>
                    </ul>
                </p>
                <h3>IV. Direitos e Obrigações do Governo de MS</h3>
                <p>
                    <em>O Governo tem o direito de:</em>
                    <ul>
                        <li>
                            • Acessar as informações inseridas para o desenvolvimento das atividades
                            objeto dos sistemas, serviços e aplicativos;
                        </li>
                        <li>
                            • Controlar a circulação de informações dentro dos sistemas, serviços e
                            aplicativos;
                        </li>
                        <li>
                            • Suspender o acesso dos Usuários e recusar futuro cadastramento caso
                            suspeite, com fundamentos, que as informações inseridas são falsas;
                        </li>
                        <li>
                            • Recusar-se ou impedir o acesso àqueles Usuários que descumpram com os
                            Termos e Condições de Uso e demais condições estabelecidas; e
                        </li>
                        <li>
                            • Não se responsabilizar pelos danos decorrentes a terceiros das falhas de
                            acesso;
                        </li>
                    </ul>
                    <em>O Governo se obriga a:</em>
                    <ul>
                        <li>
                            • Adotar práticas que protejam a privacidade do indivíduo e cumpram com os
                            princípios de Proteção de Dados no Brasil;
                        </li>
                        <li>    
                            • Priorizar a confidencialidade e sigilo dos dados pessoais inseridos nos
                            sistemas, serviços e aplicativos da SEMAGRO, utilizando de dispositivos que
                            possam garantir devidamente a segurança desses dados;
                        </li>
                        <li>
                            • Cumprir com a Política de Privacidade dos sistemas, serviços e aplicativos da
                            SEMAGRO;
                        </li>
                        <li>
                            • Não fornecer os dados pessoais do Usuário para organizações privadas; e
                        </li>
                        <li>
                            • Adotar medidas de segurança, técnicas e administrativas aptas a proteger os
                            dados pessoais de acesso não autorizados e de situações acidentais ou ilícitas
                            de destruição, perda, alteração, comunicação ou qualquer forma de tratamento
                            inadequado ou ilícito.
                        </li>
                    </ul>
                </p>
                <h3>V. Conteúdo do Aplicativo</h3>
                <p>
                    O Governo de Mato Grosso do Sul garante que as informações provenientes
                    dos sistemas, serviços e aplicativos SEMAGRO são oficiais. A qualquer momento,
                    o Governo se reserva o direito de alterar, modificar ou remover as informações
                    contidas nos sistemas, serviços e aplicativos SEMAGRO sem aviso prévio aos
                    usuários.
                </p>
                <h3>VI. Direitos Autorais</h3>
                <p>
                    É autorizada a reprodução total ou parcial sem fins lucrativos do conteúdo
                    inserido nos sistemas, serviços e aplicativos da SEMAGRO, podendo a fonte ser
                    citada, mantendo-se a integridade das informações e respeitando-se o sigilo de
                    terceiros.
                    O Usuário cede seus direitos autorais relacionados a qualquer imagem ou
                    conteúdo inserido nos sistemas, serviços e aplicativos da SEMAGRO para o
                    Governo do Estado de Mato Grosso do Sul e para a SGI a partir do momento
                    em que insere tais dados, informações, materiais ou imagens nos sistemas,
                    serviços e aplicativos.
                    Não são permitidas modificações, reproduções, armazenamentos,
                    transmissões, cópias, distribuições ou quaisquer outras formas de utilização
                    para fins comerciais dos conteúdos dos sistemas, serviços e aplicativos SEMAGRO
                    sem o consentimento prévio e formal do Governo.
                    O uso da logomarca do Governo do Estado de Mato Grosso do Sul é exclusivo
                    da organização, sendo vedada a sua utilização para qualquer fim por terceiros.
                </p>
                <h3>VII. Responsabilidade</h3>
                <p>
                    O Governo de Mato Grosso do Sul e a SGI não se responsabilizam por
                    eventuais prejuízos causados a terceiros por dados, informações, materiais ou
                    imagens inseridas pelo Usuário nos sistemas, serviços e aplicativos SEMAGRO.
                </p>
                <h3>VIII. Atualização dos Termos de Uso</h3>
                <p>
                    Este documento poderá ser alterado pelo Governo do Estado de Mato Grosso
                    do Sul a qualquer momento em que julgue conveniente.
                    Ressalta-se que em nenhuma hipótese as condições de sigilo dos dados
                    cadastrais dos Usuários serão afetadas por quaisquer modificações nesta
                    política, sendo garantido e mantido indefinidamente o sigilo de todas as
                    informações armazenadas nos bancos de dados do Governo de Mato Grosso
                    do Sul.
                </p>
                <div className="row">
                    <Checkbox
                        id="Checkbox_3"
                        label="Eu li e concordo com os termos de uso"
                        filledIn={true}
                        onChange={handleCheckBox}
                    />
                    <Button
                        node="button"
                        onClick={handleButton}
                        waves="light"
                        style={{
                            float: 'right',
                            marginTop: '20px'
                        }}
                    >
                        Proximo
                    </Button>
                    <Link to="/login" className="btn btn-back">Voltar</Link>
                </div>
                
            </Container>
            
        </div>
        
    )
}