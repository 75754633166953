import React, {useState , useEffect}  from 'react'
import { Link } from 'react-router-dom';
import api from '../../../services/api'
import tokenServer from '../../../services/tokenServer'

import HeaderProfile from '../../../components/HeaderProfile';
import Preloader from '../../../components/Preloader'

import { Container, TextInput, Row, Col, Button } from 'react-materialize';

import './style.css'

export default function Perfil() {
    const [load, setLoad] = useState(true);
    const [user, setUser ] = useState('')
    const user_id = localStorage.getItem('user_id')
    const token = localStorage.getItem('token')

    function CpfCnpj(){
        if(user.cpf){
            return(
            <TextInput
                disabled
                noLayout={true}
                id="TextInput-4"
                label="CPF"
                value={user.cpf}
            />
            )
        }else{
            return (
            <TextInput
                disabled
                noLayout={true}
                id="TextInput-4"
                label="CNPJ"
                value={user.cnpj}
            />
            )
        }
    }

    async function loadData(token, user_id){
        try {
            const access_token = await tokenServer.getToken()
            const response = await api.get(`users/${user_id}`,{ headers: {
                    authorizationuser: token,
                    Authorization: `Bearer ${access_token}`,
                }
            })
            setUser(response.data)
            
        } catch (error) {
        }
        setLoad(false)
    }

    useEffect(() => {
        loadData(token, user_id)
    }, [token, user_id])

    return(
        <div id="perfil">
            <Preloader load={load}/>
            <HeaderProfile title="Perfil"/>
            <div className="wrapper">
                <Container className="z-depth-5">
                    <h5>Dados</h5>
                    <Row>
                        <Col m={4} s={12}>
                            <TextInput
                                disabled
                                noLayout={true}
                                id="TextInput-4"
                                label="Nome"
                                value={user.name}
                            />
                        </Col>
                        <Col m={4} s={12}>
                            <CpfCnpj />  
                        </Col>
                        <Col m={4} s={12}>
                            <TextInput
                                disabled
                                noLayout={true}
                                id="TextInput-4"
                                label="Propriedade"
                                value={user.property_name}
                            />
                        </Col>
                    </Row>
                    <h5>Perfil</h5>
                    <Row>
                        <Col m={12} s={12}>
                            <TextInput
                                disabled
                                email
                                noLayout={true}
                                id="TextInput-4"
                                label="E-mail"
                                value={user.email}
                                validate
                            />
                        </Col>
                    </Row>

                    <Row>
                        <div className="col l6 s12 m6">
                        <Link to="/perfil/edit/">
                            <Button
                                    node="button"
                                    waves="light"
                                    style={{
                                        width: "100%"
                                    }}
                                >
                                    Editar
                            </Button>
                        </Link>
                        </div>
                        <div className="col l6 s12 m6">
                        <Link to="/perfil/senha/edit">
                            <Button
                                    node="button"
                                    waves="light"
                                    style={{
                                        width: "100%"
                                    }}
                                >
                                    Redefinir Senha
                            </Button>
                        </Link>
                        </div>
                    </Row>
                </Container>
            </div>
        </div>
        
    )
}