import React from 'react';

import 'materialize-css';

import Routes from './routes'

import './global.css'


function App() {
  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
