import React, { useState }  from 'react'
import { useParams } from 'react-router-dom';
import api from '../../../services/api'
import tokenServer from '../../../services/tokenServer'

import Preloader from '../../../components/Preloader'
import ModalError from '../../../components/ModalError'

import {Link}  from 'react-router-dom';
import { Container, TextInput, Row, Button } from 'react-materialize';

import './style.css'
import Header from '../../../components/Header';


export default function RecuperarSenha() {
    const params = useParams()
    const [load, setLoad] = useState(false);
    const [modal, setModal] = useState(false);
    const [url, setUrl ] = useState('')
    const [erro, setErro] = useState('');
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    async function handleSubmit(event) {
        event.preventDefault();
        setLoad(true)
        setModal(false)
        try {
            const access_token = await tokenServer.getToken()
            const data = { password, confirmPassword }
            await api.patch(`users/reset/password/${params.token}`, data,{ headers: {
                Authorization: `Bearer ${access_token}`,
            }
        })
            setErro("Senha recuperada com sucesso")
            setUrl("https://manuca.semagro.ms.gov.br/")
        } catch (error) {
            setErro("Erro, por favor verifique se os campos estão preenchidos corretamente")
        }
        setLoad(false)
        setModal(true)
    }

    return(
        <div id="nova-senha">
                <Preloader load={load}/>
                <ModalError show={modal} error={erro} url={url} />
                <Header></Header>
                <Container className="z-depth-5">
                    <form onSubmit={handleSubmit}>
                        <h5>Recuperação de Senha</h5>
                        <div className="row">
                            <TextInput
                                password
                                noLayout={true}
                                id="TextInput-4"
                                minlength="6"
                                maxlength="16"
                                placeholder="Senha"
                                value={password}
                                onChange={event => setPassword(event.target.value)}

                            />
                            <TextInput
                                password
                                noLayout={true}
                                id="TextInput-4"
                                minlength="6"
                                maxlength="16"
                                placeholder="Repetir Senha"
                                value={confirmPassword}
                                onChange={event => setConfirmPassword(event.target.value)}

                            />
                        </div>
                        <Row>
                            <Button
                                type="submit"
                                node="button"
                                waves="light"
                                style={{
                                    float: 'right'
                                }}
                            >
                                Recuperar
                            </Button>
                            <Link to="/login" className="btn btn-voltar">Voltar</Link>

                        </Row>
                    </form>
                </Container>
            </div>
        
    )
}