import React, { useState, useEffect }  from 'react'
import api from '../../../services/api'
import tokenServer from '../../../services/tokenServer'
import { Link } from 'react-router-dom';

import HeaderProfile from '../../../components/HeaderProfile';
import Preloader from './../../../components/Preloader'
import { Container, TextInput, Row, Col, Button } from 'react-materialize';

import './style.css'

export default function ListEndereco() {
    const [load, setLoad] = useState(true);
    const [addresses, setAddresses] = useState([])
    const token = localStorage.getItem('token')

    async function loadAddress(){
        try {
            const access_token = await tokenServer.getToken()
            const response = await api.get('address',{ headers: {
                    authorizationuser: token,
                    Authorization: `Bearer ${access_token}`,
                }
            })
            setAddresses(response.data)
        } catch (error) {
        }   
        setLoad(false)
    }

    useEffect(() => {
        loadAddress()
    }, [token])

    async function handleDeleteEndereco(id){
        try {
            const access_token = await tokenServer.getToken()
            await api.delete(`address/${id}`,{ headers: {
                authorizationuser: token,
                Authorization: `Bearer ${access_token}`,
            }
            })
            const newList = addresses.filter(address => address.id !== id)
            setAddresses(newList)
        } catch (error) {
            alert("Erro ao excluir")
        }
    }


    return(
        <div id="list-endereco">
            <Preloader load={load}/>
            <HeaderProfile title="Endereços" />
            <div className="wrapper">

            
            <Container>
                
            {
                addresses.map((address,index) => (
                    <div key={index} className="container-endereco z-depth-5">
                        <h5> Endereço {index + 1}</h5>
                <Row>
                    <Col m={4} s={12}>
                        <TextInput 
                            disabled
                            noLayout={true}
                            id="TextInput-4"
                            label="CEP"
                            value={address.postal_code}
                        />
                    </Col>
                    <Col m={6} s={12}>
                        <TextInput 
                            disabled
                            noLayout={true}
                            id="TextInput-4"
                            label="Rua"
                            value={address.address}
                        />
                    </Col>
                    <Col m={2} s={12}>
                        <TextInput 
                            disabled
                            noLayout={true}
                            id="TextInput-4"
                            label="Numero"
                            value={address.number}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col m={4} s={12}>
                        <TextInput 
                            disabled
                            noLayout={true}
                            id="TextInput-4"
                            label="Bairro"
                            value={address.district}
                        />
                    </Col>
                    <Col m={4} s={12}>
                        <TextInput 
                            disabled
                            noLayout={true}
                            id="TextInput-4"
                            label="UF"
                            value={address.state}
                        />
                    </Col>
                    <Col m={4} s={12}>
                        <TextInput 
                            disabled
                            noLayout={true}
                            id="TextInput-4"
                            label="Cidade"
                            value={address.city}
                        />
                    </Col>
                    
                </Row>
                <Row>
                    <Button
                            className="button red lighten-1"
                            node="button"
                            type="submit"
                            waves="light"
                            onClick={()=> handleDeleteEndereco(address.id)}
                            style={{
                                float: 'right',
                                width: "49%",
                            }}
                            >
                            Excluir
                    </Button>`
                <Link to={`endereco/${address.id}`}>
                    <Button
                            node="button"
                            type="submit"
                            waves="light"
                            style={{
                                float: 'left',
                                width: "49%",
                            }}
                            >
                            Editar
                    </Button>
                </Link>
                </Row>
                    </div>
                ))
            }
            
            </Container>
            </div>
            <Container>
                <Link to="novo-endereco">
                    <Button
                            node="button"
                            type="submit"
                            waves="light"
                            style={{
                                width: "100%",
                                margin: "40px auto",
                            }}
                            >
                            Novo
                    </Button>
                </Link>
            </Container>
        </div>
        
    )
}