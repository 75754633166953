import React, {useState , useEffect}  from 'react'
import api from '../../../services/api'
import tokenServer from '../../../services/tokenServer'

import Preloader from '../../../components/Preloader'
import ModalError from '../../../components/ModalError'
import HeaderProfile from '../../../components/HeaderProfile';
import { Container, TextInput, Row, Col, Button } from 'react-materialize';

import './style.css'

export default function PerfilEdit() {
    const [load, setLoad] = useState(true);
    const [modal, setModal] = useState(false);
    const [erro, setErro] = useState('');
    const [url, setUrl] = useState('');
    const [isCpf, setIsCpf] = useState('')
    const [isCnpj, setIsCnpj] = useState('')
    const [name, setName] = useState('');
    const [cpf, setCpf] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [property_name, setProperty_name] = useState('');
    const [email, setEmail] = useState('');
    const user_id = localStorage.getItem('user_id')
    const user_type = localStorage.getItem('user_type')
    const token = localStorage.getItem('token')

    function CpfMask(v){
        v = v.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        v = v.replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        v = v.replace(/(\d{3})(\d)/, '$1.$2')
        v = v.replace(/(\d{3})(\d{1,2})/, '$1-$2')
        v = v.replace(/(-\d{2})\d+?$/, '$1') 
        return v;
    }

    function CnpjMask(v){
        v = v.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        v = v.replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        v = v.replace(/(\d{3})(\d)/, '$1.$2')
        v = v.replace(/(\d{3})(\d)/, '$1/$2')
        v = v.replace(/(\d{4})(\d{1,2})/, '$1-$2')
        v = v.replace(/(-\d{2})\d+?$/, '$1') 
        return v;
    }

    async function loadData(token, user_id){
        try {
            const access_token = await tokenServer.getToken()
            const response = await api.get(`users/${user_id}`,{ headers: {
                    authorizationuser: token,
                    Authorization: `Bearer ${access_token}`,
                }
            })
            setName(response.data.name)
            if(response.data.cpf.length === 14){
                setIsCnpj('hide')
            }else{
                setIsCpf('hide')
            }
            setCpf(response.data.cpf)
            setCnpj(response.data.cnpj)
            setEmail(response.data.email)
            setProperty_name(response.data.property_name)
            
        } catch (error) {
        }
        setLoad(false)
    }

    useEffect(() => {
        loadData(token, user_id)
    }, [token, user_id])

    function handleCpf(event){
        setCpf(CpfMask(event.target.value))
    }

    function handleCnpj(event){
        setCpf(CnpjMask(event.target.value))
    }

    async function handleEdit(event){
        event.preventDefault();
        setModal(false)
        setLoad(true)
        const access_token = await tokenServer.getToken()
        let data = {}
        if(cnpj){
            data = {
                name,
                cnpj,
                email,
                user_type,
                property_name,
            }
        }else{
            data = {
                name,
                cpf,
                email,
                user_type,
                property_name,
            }
        }
        
        try {
            await api.put(`users/${user_id}`,data ,{ headers: {
                authorizationuser: token,
                Authorization: `Bearer ${access_token}`,
            }
            })
            setUrl("/perfil")
            setErro("Perfil atualizado")
            setLoad(false)
            setModal(true)
        } catch (error) {
            setErro("Erro, por favor verifique se os campos estão preenchidos corretamente")
        }
        setLoad(false)
        setModal(true)
    }

    return(
        <div id="perfil-edit">
            <Preloader load={load}/>
            <ModalError show={modal} error={erro} url={url} />
            <HeaderProfile title="Editar Perfil"/>
            <div className="wrapper">
                <Container className="z-depth-5">
                    <form onSubmit={handleEdit}>
                        <h5>Dados</h5>
                        <Row>
                            <Col m={4} s={12}>
                                <TextInput
                                    noLayout={true}
                                    id="TextInput-4"
                                    label="Nome"
                                    value={name}
                                    onChange={event => setName(event.target.value)}
                                />
                            </Col>
                            <Col m={4} s={12} className={isCpf}>
                                <TextInput
                                    noLayout={true}
                                    id="TextInput-4"
                                    label="CPF"
                                    value={cpf}
                                    onChange={handleCpf}
                                />
                            </Col>
                            <Col m={4} s={12} className={isCnpj}>
                                <TextInput
                                    noLayout={true}
                                    id="TextInput-4"
                                    label="CPF"
                                    value={cnpj}
                                    onChange={handleCnpj}
                                />
                            </Col>
                            <Col m={4} s={12}>
                                <TextInput
                                    noLayout={true}
                                    id="TextInput-4"
                                    label="Propriedade"
                                    value={property_name}
                                    onChange={event => setProperty_name(event.target.value)}
                                />
                            </Col>
                        </Row>
                        <h5>Perfil</h5>
                        <Row>
                            <Col m={12} s={12}>
                                <TextInput
                                    email
                                    noLayout={true}
                                    id="TextInput-4"
                                    label="E-mail"
                                    value={email}
                                    validate
                                    onChange={event => setEmail(event.target.value)}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Button
                                    node="button"
                                    waves="light"
                                    type="submit"
                                    style={{
                                        float: 'right'
                                    }}
                            >
                                    Atualizar
                            </Button>
                        </Row>
                    </form>
                </Container>
            </div>
        </div>
        
    )
}