import React, { useState }  from 'react'
import api from '../../../services/api'
import tokenServer from '../../../services/tokenServer'

import Preloader from '../../../components/Preloader'
import ModalError from '../../../components/ModalError'
import {Link}  from 'react-router-dom';
import { Container, TextInput, Row, Button } from 'react-materialize';

import Header from '../../../components/Header';
import './style.css'

export default function EnvioEmail() {
    const [load, setLoad] = useState(false);
    const [modal, setModal] = useState(false);
    const [url, setUrl ] = useState('');
    const [erro, setErro] = useState('');
    const [email, setEmail] = useState('')

    async function handleSubmit(event) {
        event.preventDefault();
        setLoad(true)
        setModal(false)
        try {
            const access_token = await tokenServer.getToken()
            const data = { email }
            await api.post('users/recovery/password', data,{ headers: {
                Authorization: `Bearer ${access_token}`,
            }
            })
            setErro("Enviamos um email com um link para recuperar a senha")
            setUrl('/')
        } catch (error) {
            setErro("O email nao esta cadastrado, por favor coloque um email valido")
        }
       setLoad(false)
       setModal(true)
    }

    return(
        <div id="recuperar-senha">
                <Preloader load={load}/>
                <ModalError show={modal} error={erro} url={url} />
                <Header></Header>
                <Container className="z-depth-5">
                    <form onSubmit={handleSubmit}>
                        <h5>Recuperação de Senha</h5>
                        <Row>
                            <TextInput
                                email
                                validate
                                noLayout={true}
                                id="TextInput-4"
                                label="E-mail cadastrado"
                                value={email}
                                onChange={event => setEmail(event.target.value)}

                            />
                        </Row>
                        <Row>
                            <Link to="/login" className="btn btn-voltar">Voltar</Link>
                            <Button
                                type="submit"
                                node="button"
                                waves="light"
                                style={{
                                    float: 'right'
                                }}
                            >
                                Enviar
                            </Button>
                        </Row>
                    </form>
                </Container>
            </div>
        
    )
}